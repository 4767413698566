// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#codesnippets-container {
  height: calc(100vh - 80px);
  overflow: auto;
}`, "",{"version":3,"sources":["webpack://./src/pages/Fundjs/CodeSnippets/CodeSnippets.scss"],"names":[],"mappings":"AAAA;EACE,0BAAA;EACA,cAAA;AACF","sourcesContent":["#codesnippets-container {\r\n  height: calc(100vh - 80px);\r\n  overflow: auto;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
