import "./Switch.scss";

const Switch = ({
  id,
  name,
  label,
  checked,
  value,
  onChange,
  disabled,
  onClick,
}) => {
  return (
    <div id="switch-container">
      <label className="switch">
        <input
          type="checkbox"
          value={value}
          id={id}
          name={name}
          checked={checked}
          onChange={onChange}
          onClick={onClick}
          disabled={disabled}
        />
        <span className="slider round"></span>
      </label>
      {/* <div className="form-check form-switch">
        <input
          className="form-check-input"
          type="checkbox"
          value={value}
          id={id}
          name={name}
          checked={checked}
          onChange={onChange}
          onClick={onClick}
          disabled={disabled}
        />
        <label className="form-check-label" htmlFor={id}>
          {label}
        </label>
      </div> */}
    </div>
  );
};

export default Switch;
