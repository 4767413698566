import React from "react";
import { useSelector } from "react-redux";
import "./OoOQuickStart.scss";
import SyntaxHighlighter from "react-syntax-highlighter";
import { atomOneDark } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { SyntexHighlight } from "components";

const OoOQuickStart = () => {
  const { isDarkMode } = useSelector((state) => state.global) || {};
  const textColorClass = isDarkMode ? "text-white" : "text-black";
  const scrollClass = isDarkMode ? "custom-scroll-dark" : "custom-scroll";
  const syntaxStyle = atomOneDark;

  return (
    <div id="oooquickstart-container" className={`cp-38 ${scrollClass}`}>
      <div className={`contract-addresses container ${textColorClass}`}>
        <h1 id="ooo-quickstart" className="mb-3">
          <a href="#ooo-quickstart" className="header-anchor">
            #
          </a>
          OoO Quickstart
        </h1>
        <div className="mt-5 mb-3">
          <h3 className="border-bottom border-1 pb-2">
            <a href="#ooo-contract-addresses" className="h-anchor">
              #
            </a>
            Integration
          </h3>
        </div>
        <p className="text-16-300">
          In order to request data, and enable data provision in your smart
          contract, you will need to import the <code>ConsumerBase.sol</code>{" "}
          smart contract and set up two simple functions within your smart
          contract.
        </p>
        <ol>
          <li>Add the package to your project:</li>
        </ol>
        <SyntexHighlight text={`yarn add @unification-com/xfund-router`} />
        <ol start="2">
          <li>
            In your smart contract, import <code>ConsumerBase.sol</code>:
          </li>
        </ol>
        <SyntexHighlight
          text={`import "@unification-com/xfund-router/contracts/lib/ConsumerBase.sol";`}
        />
        <ol start="3">
          <li>
            Extend your contract, adding <code>is ConsumerBase</code>:
          </li>
        </ol>
        <SyntexHighlight text={`contract MyConsumer is ConsumerBase {}`} />
        <ol start="4">
          <li>
            Ensure your <code>constructor</code> function has a parameter to
            accept the <code>Router</code> and <code>xFUND</code>
            smart contract addresses, and pass it to the{" "}
            <code>ConsumerBase</code>:
          </li>
        </ol>
        <SyntaxHighlighter language="javascript" style={syntaxStyle}>
          {`constructor(address _router, address _xfund) 
          public ConsumerBase(_router, _xfund) {
            // other stuff...
          }`}
        </SyntaxHighlighter>
        <div
          className={`${
            isDarkMode ? "noteBlockD" : "noteBlock"
          } custom-block tip`}
        >
          <strong className="custom-block-title">Note</strong>
          <div>
            See <a href="/ooo/contracts.html">Contract Addresses</a> for the
            latest contract addresses
          </div>
        </div>
        <ol start="5">
          <li>
            Implement the <code>receiveData</code> function for data Providers
            to send data, e.g.
          </li>
        </ol>
        <SyntaxHighlighter language="javascript" style={syntaxStyle}>
          {`function receiveData(uint256 _price, bytes32 _requestId) internal override {
    price = _price;
}`}
        </SyntaxHighlighter>
        <div
          className={`${
            isDarkMode ? "noteBlockD" : "noteBlock"
          } custom-block tip`}
        >
          <strong className="custom-block-title">Note</strong>
          <div>
            Thus must be <code>internal</code> and override the{" "}
            <code>ConsumerBase</code>'s <code>receiveData</code>.
          </div>
        </div>
        <ol start="6">
          <li>Implement a function to request data, for example:</li>
        </ol>
        <SyntaxHighlighter language="javascript" style={syntaxStyle}>
          {`function getData(address _provider, uint256 _fee, bytes32 _data) external returns (bytes32) {
    return _requestData(_provider, _fee, _data);
}`}
        </SyntaxHighlighter>

        <ol start="7">
          <li>
            Deploy. For example, using <code>truffle</code>, a very simple
            migration script for <code>Rinkeby</code> testnet may look like:
          </li>
        </ol>
        <SyntaxHighlighter language="javascript" style={syntaxStyle}>
          {`// Load my contract which implements ConsumerLib
const MyConsumer = artifacts.require("MyConsumer")

module.exports = function(deployer) {
  // deploy, passing the Router and xFUND smart contract addresses
  deployer.deploy(MyConsumer, "0x...ROUTER_ADDRES", "0x...XFUND_ADDRESS")
}`}
        </SyntaxHighlighter>
        <div className="mt-5 mb-3">
          <h3 className="border-bottom border-1 pb-2">
            <a href="#ooo-contract-addresses" className="h-anchor">
              #
            </a>
            Initialisation
          </h3>
        </div>
        <p>
          Once integrated, compiled, and deployed, you will need to send some
          transactions to the Ethereum blockchain to initialize the fee payment
          and data acquisition environment. This involves:
        </p>
        <ol>
          <li>
            <p>
              Increasing the <code>xFUND</code> token allowance on the{" "}
              <code>Router</code> smart contract, in order for the{" "}
              <code>Router</code> to accept and pay xFUND fees to data
              providers. This needs to be run once if the initial allowance is
              set high enough.
            </p>
          </li>
          <li>
            <p>
              Transfer some <code>xFUND</code> tokens to your smart contract,
              that is integrating <code>ConsumerBase</code>. This allows you to
              submit data requests, and your contract to pay fees. The required
              amount of <code>xFUND</code> to pay for a request fee is sent to
              the <code>Router</code> with each request. Your contract may need
              periodically topping up with <code>xFUND</code>, depending on how
              you implement fee payment.
            </p>
            <div className="mt-3">
              <div className={`custom-block tip d-flex gap-2`}>
                <strong className="custom-block-title">Note: </strong>
                <div>
                  The <code>xFUNDMOCK</code> Token on Rinkeby testnet has a
                  faucet function,
                  <code>gimme()</code> which can be used to grab some test
                  tokens.
                </div>
              </div>
            </div>
          </li>
        </ol>
      </div>
    </div>
  );
};

export default OoOQuickStart;
