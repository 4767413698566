import React from "react";
import { useSelector } from "react-redux";

const Integration = () => {
  const reduxData = useSelector((state) => state.global);
  const { isDarkMode } = reduxData || {};
  return (
    <div
      id="integration-container"
      className={`cp-38 ${isDarkMode ? "custom-scroll-dark" : "custom-scroll"}`}
    >
      Integration
    </div>
  );
};

export default Integration;
