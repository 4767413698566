import { useSelector } from "react-redux";

import { times } from "lodash";

import "react-toastify/dist/ReactToastify.css";
import "./Dashboard.scss";
import { useState } from "react";
import moment from "moment";

const Dashboard = () => {
  const reduxData = useSelector((state) => state.global);
  const { isSubscription, isDarkMode } = reduxData || {};

  const [loading, setLoading] = useState(false);

  const currentHour = +moment().format("HH");
  let greetingMessage = "Good Morning";
  if (currentHour < 12) greetingMessage = "Good Morning";
  else if (currentHour >= 12 && currentHour <= 17)
    greetingMessage = "Good Afternoon";
  else if (currentHour >= 17 && currentHour <= 24)
    greetingMessage = "Good Evening";

  const first_name = "";

  return (
    <div
      id={`${isDarkMode ? "dashboard-container-dark" : "dashboard-container"}`}
      className={`cps-38 cpe-38 cpt-26 w-100 ${
        isDarkMode ? "custom-scroll-dark" : "custom-scroll"
      }`}
    >
      {loading ? (
        <>
          <div
            className={`${
              isDarkMode ? "skeleton-dark" : "skeleton"
            } h-40 br-10 w-50 mb-2`}
          />
          <div
            className={`${
              isDarkMode ? "skeleton-dark" : "skeleton"
            } h-20 br-8 w-50`}
          />
          {isSubscription && (
            <div
              className={`${
                isDarkMode ? "skeleton-dark" : "skeleton"
              } h-99 br-8 cmt-30 g-0 `}
            />
          )}
          <div
            className={`${
              isDarkMode ? "skeleton-dark" : "skeleton"
            } h-30 br-8 cmt-38 g-0 `}
          />
          <div className="row cmt-20">
            {times(3, (index) => {
              return (
                <div className="col-md-4" key={index}>
                  <div
                    className={`br-14 cp-18 ${
                      isDarkMode ? "skeleton-dark" : "skeleton"
                    }`}
                  >
                    <div className="d-flex justify-content-between">
                      <div>
                        <div
                          className={`text-12-500 ${
                            isDarkMode ? "color-c9c9" : "color-7e91"
                          } v-hide`}
                        >
                          0
                        </div>
                        <div
                          className={`text-24-600 ${
                            isDarkMode ? "color-ffff" : "color-2749"
                          } mb-3 v-hide`}
                        >
                          0
                        </div>
                      </div>
                    </div>
                    <div
                      className={`text-16-400 ${
                        isDarkMode ? "color-c9c9" : "color-7e91"
                      } v-hide`}
                    >
                      0
                    </div>
                    <div className="d-flex cmt-32 v-hide">
                      <div className="f-center gap-2 cpt-8 cpb-10 cps-10 cpe-10 br-8 pointer">
                        <span
                          className={`text-12-600 ${
                            isDarkMode ? "color-ffff" : "color-2749"
                          }`}
                        >
                          API Key
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </>
      ) : (
        <>
          <div
            className={`text-26-700 un fb-center ${
              isDarkMode ? "color-ffff" : "color-2749"
            }`}
          >
            {greetingMessage}, 👋 {`${first_name ? `${first_name}!` : ""} `}
          </div>
        </>
      )}
    </div>
  );
};

export default Dashboard;
