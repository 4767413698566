// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#checkbox-container .form-check {
  margin-bottom: unset;
  min-height: unset;
}
#checkbox-container .form-check-input:checked {
  background-color: var(--56ff);
  border-color: var(--56ff);
}`, "",{"version":3,"sources":["webpack://./src/components/inputs/Checkbox/Checkbox.scss"],"names":[],"mappings":"AACE;EACE,oBAAA;EACA,iBAAA;AAAJ;AAEE;EACE,6BAAA;EACA,yBAAA;AAAJ","sourcesContent":["#checkbox-container {\r\n  .form-check {\r\n    margin-bottom: unset;\r\n    min-height: unset;\r\n  }\r\n  .form-check-input:checked {\r\n    background-color: var(--56ff);\r\n    border-color: var(--56ff);\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
