import "./Dashboard.scss";
import React, { useState } from "react";
import { useSelector } from "react-redux";

const Dashboard = () => {
  const reduxData = useSelector((state) => state.global);
  const { isDarkMode } = reduxData || {};

  return (
    <div
      id="dashboard-container"
      className={`cp-38 ${isDarkMode ? "custom-scroll-dark" : "custom-scroll"}`}
    >
      DashBoard
    </div>
  );
};

export default Dashboard;
