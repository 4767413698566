import { useEffect, useRef, useState } from "react";
import { icons } from "utils/constants";
import { useNavigate } from "react-router-dom";
import "./GlobalSearch.scss";
import { useSelector } from "react-redux";

const GlobalSearch = ({ isSubscription }) => {
  const myRef = useRef();
  const navigate = useNavigate();
  const reduxData = useSelector((state) => state.global);
  const { isDarkMode } = reduxData || {};
  const [show, setShow] = useState(false);
  const [searchText, setSearchText] = useState("");
  const handleClickOutside = (e) => {
    if (myRef && myRef?.current && !myRef.current.contains(e.target)) {
      setShow(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  });
  const options = [
    {
      title: "Main Dashboard",
      subTitle: "",
      redirect: "/main-dashboard",
      show: true,
    },
    {
      title: "Unode",
      subTitle: "",
      redirect: "/unode/dashboard",
      show: true,
    },
    {
      title: "OoO",
      subTitle: "",
      redirect: "/ooo/dashboard",
      show: true,
    },
    {
      title: "VOR",
      subTitle: "",
      redirect: "/vor/dashboard",
      show: true,
    },
    {
      title: "Fundjs",
      subTitle: "",
      redirect: "/fund-js",
      show: true,
    },
    {
      title: "TestNet Faucet",
      subTitle: "",
      redirect: "/testnet-faucet",
      show: true,
    },
    {
      title: "WrkChain",
      subTitle: "",
      redirect: "/wrk-chain",
      show: true,
    },
    {
      title: "BEACON",
      subTitle: "",
      redirect: "/beacon",
      show: true,
    },
    // {
    //   title: "Superfluid",
    //   subTitle: "View inflow/outflow in superfluid dashboard",
    //   redirect: "superfluid",
    //   show: isSubscription,
    // },
    // {
    //   title: "Manage Nodes",
    //   subTitle: "Create & manage nodes",
    //   redirect: "/nodes",
    //   show: true,
    // },
  ];
  const handleChange = (e) => {
    setSearchText(e.target.value);
  };
  const displayList = options.filter(
    (o) =>
      o.title.toLowerCase().includes(searchText.toLowerCase()) ||
      o.subTitle.toLowerCase().includes(searchText.toLowerCase())
  );
  return (
    <div
      id={`${
        isDarkMode ? "globalsearch-container-dark" : "globalsearch-container"
      }`}
      ref={myRef}
    >
      <span className="s-icon-block">
        <img
          src={isDarkMode ? icons.searchLight : icons.search}
          alt="wallet"
          className="fit-image"
        />
      </span>
      <input
        type="text"
        placeholder="Search something here..."
        onChange={handleChange}
        value={searchText}
        onFocus={() => {
          setShow(true);
        }}
        // maxLength="40"
      />
      {displayList && show && (
        <div
          className={`global-search-menu-list ${
            isDarkMode ? "custom-scroll-dark" : "custom-scroll"
          }`}
        >
          {displayList.map((elm, index) => {
            const { title, subTitle, redirect, show } = elm;
            return (
              <div
                key={index}
                className={`global-search-item ${show ? "" : "d-none"}`}
                onClick={() => {
                  if (redirect === "superfluid") {
                    window.open(
                      "https://app.superfluid.finance/token/sepolia/0x783fdeab6951504c4601184587aafed01511ed24",
                      "_blank"
                    );
                  } else {
                    navigate(redirect);
                  }
                  setShow(false);
                  setSearchText("");
                }}
              >
                <div
                  className={`text-16-600 ${
                    isDarkMode ? "color-ffff" : "color-2749"
                  }`}
                >
                  {title}
                </div>
                {subTitle && (
                  <div
                    className={`text-14-400 ${
                      isDarkMode ? "color-c9c9" : "color-7e91"
                    }`}
                  >
                    {subTitle}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default GlobalSearch;
