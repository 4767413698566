// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#layout-container {
  display: flex;
  height: 100vh;
}`, "",{"version":3,"sources":["webpack://./src/layout/MainLayout/Layout.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,aAAA;AACF","sourcesContent":["#layout-container {\r\n  display: flex;\r\n  height: 100vh;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
