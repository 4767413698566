import { Label } from "components";
import "./Textinput.scss";

const Textinput = ({
  id,
  name,
  error,
  label,
  value,
  onBlur,
  disabled,
  onChange,
  required,
  placeholder,
  type,
  isDarkMode,
  maxLength,
  info,
  className,
}) => {
  return (
    <div
      id={`${isDarkMode ? "textinput-container-dark" : "textinput-container"}`}
    >
      {label && <Label label={label} required={required} info={info} />}
      <div className="text-input-container">
        <div className="text-input-block">
          <input
            id={id}
            name={name}
            className={className || ""}
            type={type || "text"}
            value={value}
            onBlur={onBlur}
            autoComplete="new-password"
            onChange={onChange}
            disabled={disabled}
            placeholder={placeholder}
            maxLength={maxLength}
          />
        </div>
        {error && <div className="input-error">{error}</div>}
      </div>
    </div>
  );
};

export default Textinput;
