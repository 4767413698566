import { Outlet, useNavigate } from "react-router-dom";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import "./Layout.scss";

const Layout = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.clear();
    localStorage.removeItem("accountData");
    navigate("/login");
  };

  return (
    <div id="layout-container">
      <Sidebar handleLogout={handleLogout} />
      <div className="flex-grow-1">
        <Navbar handleLogout={handleLogout} />
        <div className="body-container-block">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Layout;
