import { Outlet } from "react-router-dom";
import NavBar from "./NavBar";

// ==============================|| MINIMAL LAYOUT ||============================== //

const MinimalLayout = () => (
  <>
    <NavBar />
    <Outlet />
  </>
);

export default MinimalLayout;
