// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#darktheme-container .toggle {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 5rem;
  height: 2.5rem;
  margin: 0 auto;
  font-size: 0.5rem;
  overflow: hidden;
  cursor: pointer;
  outline: none;
  background: none;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  padding: 4px;
  border-radius: 15px;
}
#darktheme-container .toggle-dark {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 5rem;
  height: 2.5rem;
  margin: 0 auto;
  font-size: 0.5rem;
  overflow: hidden;
  cursor: pointer;
  outline: none;
  background: none;
  box-shadow: 0px 4px 12px rgba(197, 197, 197, 0.1);
  padding: 4px;
  border-radius: 15px;
}`, "",{"version":3,"sources":["webpack://./src/pages/DarkTheme/DarkTheme.scss"],"names":[],"mappings":"AACE;EACE,kBAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,WAAA;EACA,cAAA;EACA,cAAA;EACA,iBAAA;EACA,gBAAA;EACA,eAAA;EACA,aAAA;EACA,gBAAA;EACA,2CAAA;EACA,YAAA;EACA,mBAAA;AAAJ;AAEE;EACE,kBAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,WAAA;EACA,cAAA;EACA,cAAA;EACA,iBAAA;EACA,gBAAA;EACA,eAAA;EACA,aAAA;EACA,gBAAA;EACA,iDAAA;EACA,YAAA;EACA,mBAAA;AAAJ","sourcesContent":["#darktheme-container {\r\n  .toggle {\r\n    position: relative;\r\n    display: flex;\r\n    justify-content: space-between;\r\n    align-items: center;\r\n    width: 5rem;\r\n    height: 2.5rem;\r\n    margin: 0 auto;\r\n    font-size: 0.5rem;\r\n    overflow: hidden;\r\n    cursor: pointer;\r\n    outline: none;\r\n    background: none;\r\n    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);\r\n    padding: 4px;\r\n    border-radius: 15px;\r\n  }\r\n  .toggle-dark {\r\n    position: relative;\r\n    display: flex;\r\n    justify-content: space-between;\r\n    align-items: center;\r\n    width: 5rem;\r\n    height: 2.5rem;\r\n    margin: 0 auto;\r\n    font-size: 0.5rem;\r\n    overflow: hidden;\r\n    cursor: pointer;\r\n    outline: none;\r\n    background: none;\r\n    box-shadow: 0px 4px 12px rgba(197, 197, 197, 0.1);\r\n    padding: 4px;\r\n    border-radius: 15px;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
