import MinimalLayout from "layout/MinimalLayout";
import Login from "pages/AuthenticationPages/Login";
import OTP from "pages/AuthenticationPages/OTP/OTP";
import SignUp from "pages/AuthenticationPages/SignUp/SignUp";
import { Navigate } from "react-router-dom";

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthRoute = {
  path: "/",
  element: <MinimalLayout />,
  children: [
    {
      path: "/",
      element: <Navigate to="/login" replace />,
    },
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/signup",
      element: <SignUp />,
    },
    {
      path: "signup",
      children: [
        {
          path: "otp",
          element: <OTP />,
        },
      ],
    },
    // {
    //   path: "*",
    //   element: <Navigate to="/login" replace />,
    // },
  ],
};

export default AuthRoute;
