// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#label-container {
  margin-bottom: 8px;
}
#label-container .label-info-tip {
  padding: 11px !important;
  font-size: 14px;
  font-weight: 500;
  color: var(--7e91) !important;
  max-width: fit-content !important;
}`, "",{"version":3,"sources":["webpack://./src/components/inputs/Label/Label.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;AAAE;EACE,wBAAA;EACA,eAAA;EACA,gBAAA;EACA,6BAAA;EACA,iCAAA;AAEJ","sourcesContent":["#label-container {\r\n  margin-bottom: 8px;\r\n  .label-info-tip {\r\n    padding: 11px !important;\r\n    font-size: 14px;\r\n    font-weight: 500;\r\n    color: var(--7e91) !important;\r\n    max-width: fit-content !important;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
