import { useSelector } from "react-redux";
import "./OoODataApi.scss";
import SyntaxHighlighter from "react-syntax-highlighter";
import { atomOneDark } from "react-syntax-highlighter/dist/esm/styles/hljs";

const OoODataApi = () => {
  const { isDarkMode } = useSelector((state) => state.global) || {};
  const textColorClass = isDarkMode ? "text-white" : "text-black";
  const scrollClass = isDarkMode ? "custom-scroll-dark" : "custom-scroll";
  const syntaxStyle = atomOneDark;

  const handleScrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div id="ooodataapi-container" className={`cp-38 ${scrollClass}`}>
      <div className={`contract-addresses container ${textColorClass}`}>
        <h1 id="ooo-quickstart" className="mb-3">
          <a href="#ooo-quickstart" className="header-anchor">
            #
          </a>
          OoO Data API Guide
        </h1>
        <p>
          This guide covers the supported data offered by the Finchains OoO API,
          which is available as a data request via the xFUND Router network.
        </p>
        <div className="mt-5 mb-3">
          <h3 className="border-bottom border-1 pb-2">
            <a href="#data-providers" className="h-anchor">
              #
            </a>
            Data Providers
          </h3>
          <p>
            See Providers for a list of Oracles providing data for both Mainnet
            and Rinkeby, along with their associated fees and wallet addresses.
          </p>
          <div className="note mt-3">
            <strong>Note</strong>
            <div>
              OoO data providers may wait for 2 - 3 or more block confirmations
              before processing a request. Depending on network congestion and
              gas prices, it may therefore take up to a minute or more for data
              to be sent to your smart contract from the time your request Tx
              was received by the provider oracle.
            </div>
          </div>
          <h3 className="border-bottom border-1 pb-2 mt-4">
            <a href="#introduction" className="h-anchor">
              #
            </a>
            Introduction
          </h3>
          <p>
            Data is acquired via the OoO API using dot-separated strings to
            define the desired data - for example, "Average BTC/USD Price over
            24 hours, with outliers removed" would be requested using
            <code>BTC.USD.PR.AVI.24H</code>.
          </p>
          <p>
            The hex-encoded string is supplied along with the Provider address
            (as defined above, depending on Ethereum network) and the xFUND fee
            as parameters to your smart contract, using the{" "}
            <code>requestdata</code> function you defined.
          </p>
          <p>
            The Finchains OoO Data Provider picks up this request and supplies
            the data via the <code>receivedata</code> function you defined.
          </p>
          <h3 className="border-bottom border-1 pb-2 mt-4">
            <a href="#request-string-format" className="h-anchor">
              #
            </a>
            Request String Format
          </h3>
          <p>
            The request format follows{" "}
            <code>BASE.TARGET.TYPE.SUBTYPE[.SUPP1][.SUPP2][.SUPP3]</code>
          </p>
          <p>
            <strong>BASE</strong>, <strong>TARGET</strong>, and{" "}
            <strong>TYPE</strong> are all required parameters.{" "}
            <strong>SUBTYPE</strong> is required for type <code>PR</code>.
          </p>
          <div className="note mt-3">
            <strong>Note</strong>
            <p>
              <strong>Note:</strong> The data request string should be converted
              to a Bytes32 (Hex) value before submitting it to your smart
              contract's request function, for example:
            </p>
            <SyntaxHighlighter language="javascript" style={syntaxStyle}>
              {`const endpoint = web3.utils.asciiToHex("ETH.USDC.AD.30")
const endpoint = web3.utils.asciiToHex("BTC.USD.PR.AVI")
... etc.`}
            </SyntaxHighlighter>
          </div>
          <h4 className="mt-4">
            <a href="#request-string-format" className="h-anchor">
              #
            </a>
            BASE
          </h4>
          <p>
            The three or four-letter code for the base currency for which the
            price will be returned, e.g., <code>BTC</code> (Bitcoin),{" "}
            <code>ETH</code> (Ether), etc.
          </p>
          <h4 className="mt-4">
            <a href="#request-string-format" className="h-anchor">
              #
            </a>
            TARGET
          </h4>
          <p>
            The three or four-letter code for the target currency in which to
            return the price, e.g., <code>GBP</code>, <code>USD</code>.
          </p>
          <p>
            A full list of supported currency <code>BASE</code>/
            <code>TARGET</code> pairs is available from the{" "}
            <a
              href="https://crypto.finchains.io/api/pairs"
              target="_blank"
              rel="noopener noreferrer"
            >
              Finchains API
            </a>
            . Supported pairs specific to each exchange are linked below.
          </p>
          <h4 className="border-bottom border-1 pb-2 mt-4">
            <a href="#type" className="h-anchor">
              #
            </a>
            TYPE
          </h4>
          <p>
            The code for the data point being requested, for example{" "}
            <code>PR</code>
            etc. The currently implemented types are as follows:
          </p>
          <ul>
            <li>
              <code>PR</code>
            </li>
            <li>
              <code>AD</code>
            </li>
          </ul>
          <h4 className="mt-4">
            <a href="#type" className="h-anchor">
              #
            </a>
            TYPE: <code>PR</code>
          </h4>
          <div className="note mt-3">
            <strong>Note</strong>
            <p>
              The <code>PR</code> request type uses Finchains to query price
              data. Finchains has a limited number of supported pairs. For a
              wider range of pairs, use the <code>AD</code> endpoint, which
              queries a larger data set of DEXs.
            </p>
          </div>
          <p>
            Price, calculated using all available exchange data for the selected
            pair. See <strong>SUBTYPE</strong>s for supported query endpoints.
          </p>
          <h4 className="mt-4">
            <a href="#type" className="h-anchor">
              #
            </a>
            TYPE: <code>AD</code>
          </h4>
          <p>
            Adhoc data requests for pairs listed on supported DEXs instead of
            Finchains. The <strong>SUBTYPE</strong> is not required for the{" "}
            <code>AD</code> endpoint TYPE. The slot can however be used to
            specify the historical timespan for which to query prices.
          </p>
          <p>
            By default, the Chauvenet Criterion is used to remove outliers if
            the returned results contain enough data to do so.
          </p>
          <p>
            The OoO provider will attempt to query supported DEXs' subgraphs to
            determine whether the BASE and TARGET symbols are known to the DEX,
            and also whether the DEX has a liquidity pool representing the pair.
            If a pair exists, and is supported by OoO, it will attempt to
            retrieve the latest price from each DEX before calculating the mean
            price from all data found.
          </p>
          <p>The currently supported DEXs are:</p>
          <ul>
            <li>Uniswap v2 (Ethereum mainnet)</li>
            <li>Uniswap v3 (Ethereum mainnet)</li>
            <li>Shibaswap (Ethereum mainnet)</li>
            <li>Sushiswap (Ethereum mainnet)</li>
            <li>Quickswap (Polygon PoS)</li>
            <li>Pancakeawap v3 (BSC Chain)</li>
            <li>Honeyswap (Gnosis Chain)</li>
          </ul>
          <p>
            A list of currently supported pairs can be found in the Github
            repository used by the OoO application to update itself:{" "}
            <a
              href="https://github.com/unification-com/ooo-adhoc"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://github.com/unification-com/ooo-adhoc
            </a>
          </p>
          <div
            className={`${
              isDarkMode ? "redBlockD" : "redBlock"
            } custom-block tip`}
          >
            <strong className="custom-block-title">IMPORTANT</strong>
            <div className="mt-2">
              <code>BASE</code> and <code>TARGET</code> are{" "}
              <strong>CaSe SeNsItIvE</strong> for adhoc queries!{" "}
              <code>xFUND</code> is not the same as <code>xFUND</code>.{" "}
              <strong>
                Always check your request endpoints, and that at least one DEX
                supports the pair before sending a data request!
              </strong>
            </div>
          </div>
          <p>
            The default timespan is 0 minutes. Any integer from 0 to 60 may be
            used in <code>SUBTYPE</code>, with a <code>0</code> telling the
            oracle to only fetch the latest prices. A non-zero value tells the
            oracle to fetch prices for the past <code>nn</code> minutes.
          </p>
          <p>
            <strong>Example</strong>
          </p>
          <p>
            We know that the <code>xFUND/WETH</code> pair is listed on Uniswap
            v2 and Shibaswap. We'd like to know the average price for the last
            30 minutes, so we can use the query endpoint:
          </p>
          <p>
            <code>xFUND.WETH.AD.30</code>
          </p>
          <p>
            The OoO provider will pick up the request, and since it is an{" "}
            <code>AD</code>
            endpoint <code>TYPE</code>, will query the prices in known DEXs for
            the last 30 minutes. If a DEX supports the pair, it will query the
            latest price from all supported DEXs, and calculate the mean price
            from all results removing outliers using the Chauvenet Criterion.
          </p>
          <h4 id="subtype">
            <a href="#subtype" className="h-anchor">
              #
            </a>{" "}
            SUBTYPE
          </h4>
          <p>
            Used with <code>TYPE</code> endpoint <code>PR</code>.
          </p>
          <p>
            The data sub-type, for example <code>AVG</code> (mean),{" "}
            <code>AVI</code> (mean with outliers removed). Some{" "}
            <code>TYPE</code>s, require additional <code>SUPPN</code> data in
            the query. Some may have optional data defined in <code>SUPPN</code>
            .
          </p>
          <p>The currently implemented types are as follows:</p>
          <ul>
            <li>
              <strong>
                <span
                  style={{ cursor: "pointer", color: "blue" }}
                  onClick={() => handleScrollToSection("section-avg")}
                >
                  AVG:
                </span>
              </strong>{" "}
              Mean price calculated from all available exchange Oracles.
            </li>
            <li>
              <strong>
                <span
                  style={{ cursor: "pointer", color: "blue" }}
                  onClick={() => handleScrollToSection("section-avi")}
                >
                  AVI:
                </span>
              </strong>{" "}
              Mean price using{" "}
              <a
                href="https://www.mathwords.com/o/outlier.htm"
                target="_blank"
                rel="noopener noreferrer"
              >
                Median and Interquartile Deviation Method
              </a>{" "}
              to remove outliers.
            </li>
            <li>
              <strong>
                <span
                  style={{ cursor: "pointer", color: "blue" }}
                  onClick={() => handleScrollToSection("section-avp")}
                >
                  AVP:
                </span>
              </strong>{" "}
              Mean price with outliers removed using{" "}
              <a
                href="https://en.wikipedia.org/wiki/Peirce%27s_criterion"
                target="_blank"
                rel="noopener noreferrer"
              >
                Peirce's criterion.
              </a>{" "}
            </li>
            <li>
              <strong>
                <span
                  style={{ cursor: "pointer", color: "blue" }}
                  onClick={() => handleScrollToSection("section-avc")}
                >
                  AVC:
                </span>
              </strong>{" "}
              Mean price with outliers removed using{" "}
              <a
                href="https://en.wikipedia.org/wiki/Chauvenet%27s_criterion"
                target="_blank"
                rel="noopener noreferrer"
              >
                Chauvenet's criterion.
              </a>{" "}
            </li>
          </ul>
          <p id="section-avg" className="mt-3">
            <h5 id="subtype">
              <a href="#subtype" className="h-anchor">
                #
              </a>{" "}
              SUBTYPE: <code>AVG</code>
            </h5>
            <strong>Supported TYPEs:</strong> <code>PR</code>
          </p>
          <p>
            Average (Mean) price, calculated from all available exchange data
            for a given time period. The default timespan is 1 Hour. The
            following supported timespans can be supplied in <code>SUPP1</code>:
          </p>
          <ul>
            <li>
              <code>5M</code>: 5 Minutes
            </li>
            <li>
              <code>10M</code>: 10 Minutes
            </li>
            <li>
              <code>30M</code>: 30 Minutes
            </li>
            <li>
              <code>1H</code>: 1 Hour
            </li>
            <li>
              <code>2H</code>: 2 Hours
            </li>
            <li>
              <code>6H</code>: 6 Hours
            </li>
            <li>
              <code>12H</code>: 12 Hours
            </li>
            <li>
              <code>24H</code>: 24 Hours
            </li>
            <li>
              <code>48H</code>: 48 Hours
            </li>
          </ul>
          <p>
            <strong>Examples:</strong>
            <br />
            <code>BTC.USD.PR.AGV</code> - Mean BTC/USD price from all available
            exchanges, using data from the last hour.
            <br />
            <code>BTC.USD.PR.AGV.30M</code> - as above, but data from the last
            30 minutes.
          </p>
          <p id="section-avi" className="mt-3">
            <h5 id="subtype">
              <a href="#subtype" className="h-anchor">
                #
              </a>{" "}
              SUBTYPE: <code>AVI</code>
            </h5>
            <strong>Supported TYPEs:</strong> <code>PR</code>
          </p>
          <p>
            Average (Mean) price, calculated from all available exchange data
            for a given time period, with outliers (very high or very low
            values) removed from the calculation.
          </p>
          <p>
            The default timespan is 1 Hour. The following supported timespans
            can be supplied in <code>SUPP1</code>:
          </p>
          <ul>
            <li>
              <code>5M</code>: 5 Minutes
            </li>
            <li>
              <code>10M</code>: 10 Minutes
            </li>
            <li>
              <code>30M</code>: 30 Minutes
            </li>
            <li>
              <code>1H</code>: 1 Hour
            </li>
            <li>
              <code>2H</code>: 2 Hours
            </li>
            <li>
              <code>6H</code>: 6 Hours
            </li>
            <li>
              <code>12H</code>: 12 Hours
            </li>
            <li>
              <code>24H</code>: 24 Hours
            </li>
            <li>
              <code>48H</code>: 48 Hours
            </li>
          </ul>
          <p>
            <strong>Examples:</strong>
            <br />
            <code>BTC.USD.PR.AVI</code> - Mean BTC/USD price with outliers
            removed, using data from the last hour.
            <br />
            <code>BTC.USD.PR.AVI.30M</code> - as above, but data from the last
            30 minutes.
          </p>
          <p id="section-avp" className="mt-3">
            <h5 id="subtype">
              <a href="#subtype" className="h-anchor">
                #
              </a>{" "}
              SUBTYPE: <code>AVP</code>
            </h5>
            <strong>Supported TYPEs:</strong> <code>PR</code>
          </p>
          <p>
            Mean price with outliers removed using{" "}
            <a
              href="https://en.wikipedia.org/wiki/Peirce%27s_criterion"
              target="_blank"
              rel="noopener noreferrer"
            >
              Peirce's criterion
            </a>
          </p>
          <p>
            The default timespan is 1 Hour. The following supported timespans
            can be supplied in <code>SUPP1</code>:
          </p>
          <ul>
            <li>
              <code>5M</code>: 5 Minutes
            </li>
            <li>
              <code>10M</code>: 10 Minutes
            </li>
            <li>
              <code>30M</code>: 30 Minutes
            </li>
            <li>
              <code>1H</code>: 1 Hour
            </li>
            <li>
              <code>2H</code>: 2 Hours
            </li>
            <li>
              <code>6H</code>: 6 Hours
            </li>
            <li>
              <code>12H</code>: 12 Hours
            </li>
            <li>
              <code>24H</code>: 24 Hours
            </li>
            <li>
              <code>48H</code>: 48 Hours
            </li>
          </ul>
          <p>
            <strong>Examples:</strong>
            <br />
            <code>BTC.USD.PR.AVP</code> - Mean BTC/USD price with outliers
            removed, using data from the last hour.
            <br />
            <code>BTC.USD.PR.AVP.30M</code> - as above, but data from the last
            30 minutes.
          </p>
          <p id="section-avc" className="mt-3">
            <h5 id="subtype">
              <a href="#subtype" className="h-anchor">
                #
              </a>{" "}
              SUBTYPE: <code>AVC</code>
            </h5>
            <strong>Supported TYPEs:</strong> <code>PR</code>
          </p>
          <p>
            Mean price with outliers removed using{" "}
            <a
              href="https://en.wikipedia.org/wiki/Chauvenet%27s_criterion"
              target="_blank"
              rel="noopener noreferrer"
            >
              Chauvenet's criterion
            </a>
          </p>
          <p>
            The default timespan is 1 Hour. The following supported timespans
            can be supplied in <code>SUPP1</code>:
          </p>
          <ul>
            <li>
              <code>5M</code>: 5 Minutes
            </li>
            <li>
              <code>10M</code>: 10 Minutes
            </li>
            <li>
              <code>30M</code>: 30 Minutes
            </li>
            <li>
              <code>1H</code>: 1 Hour
            </li>
            <li>
              <code>2H</code>: 2 Hours
            </li>
            <li>
              <code>6H</code>: 6 Hours
            </li>
            <li>
              <code>12H</code>: 12 Hours
            </li>
            <li>
              <code>24H</code>: 24 Hours
            </li>
            <li>
              <code>48H</code>: 48 Hours
            </li>
          </ul>
          <p>
            The default value for <code>dMax</code> (max standard deviations) is
            3. A custom threshold can be supplied as an integer value in{" "}
            <code>SUPP2</code>.
          </p>
          <div
            className={`${
              isDarkMode ? "redBlockD" : "redBlock"
            } custom-block tip`}
          >
            <strong className="custom-block-title">IMPORTANT</strong>
            <div className="mt-2">
              If a custom <code>dMax</code> value is required, then{" "}
              <strong>timespan must also be set in </strong> <code>SUPP1</code>
            </div>
          </div>
          <p>
            <code>BTC.USD.PR.AVC</code> - Mean BTC/USD price with outliers
            removed, using data from the last hour.
            <br />
            <code>BTC.USD.PR.AVC.30M</code> - as above, but data from the last
            30 minutes
            <code>BTC.USD.PR.AVC.24H.2</code> - as above, but data from the last
            24 hours, with <code>dMax</code> of 2
          </p>
        </div>
        <div className="mt-5 mb-3">
          <h4 className="border-1 pb-2">
            <a href="#data-providers" className="h-anchor">
              #
            </a>
            SUPP1
          </h4>
          <p>
            Any supplementary request data, e.g. GDX (coinbase) etc. required
            for <code>TYPE.SUBTYPE</code> queries such as <code>EX.LAT</code>,
            or timespan values for <code>AVG</code>
            and <code>AVI</code> calculations etc.
          </p>
          <p>
            These are outlined in the respective <code>TYPE</code> or{" "}
            <code>SUBTYPE</code> definitions above where appropriate.
          </p>
        </div>
        <div className="mt-5 mb-3">
          <h4 className="border-1 pb-2">
            <a href="#data-providers" className="h-anchor">
              #
            </a>
            SUPP2
          </h4>
          <p>
            Any supplementary request data in addition to <code>SUPP1</code>,
            e.g. <code>GDX</code>
            (coinbase) etc. required for comparisons on <code>TYPE</code>s.
          </p>
          <p>
            These are outlined in the respective <code>TYPE</code> or{" "}
            <code>SUBTYPE</code> definitions above where appropriate.
          </p>
        </div>
        <div className="mt-5 mb-3">
          <h4 className="border-1 pb-2">
            <a href="#data-providers" className="h-anchor">
              #
            </a>
            SUPP3
          </h4>
          <p>
            Any supplementary request data in addition to <code>SUPP1</code> and{" "}
            <code>SUPP2</code>.
          </p>
          <p>
            These are outlined in the respective <code>TYPE</code> or{" "}
            <code>SUBTYPE</code> definitions above where appropriate.
          </p>
        </div>
        <div className="mt-5 mb-3">
          <h3 className="border-bottom border-1 pb-2">
            <a href="#data-providers" className="h-anchor">
              #
            </a>
            Return data
          </h3>
          <br />
          <p>
            All price data is supplied by the Oracle as{" "}
            <code>actualPrice * (10 ^ 18)</code>
            to standardise decimal removal, and allow integer calculations in
            smart contracts.
          </p>
        </div>
        <div className="mt-5 mb-3">
          <h3 className="border-bottom border-1 pb-2">
            <a href="#data-providers" className="h-anchor">
              #
            </a>
            Examples
          </h3>
          <br />
          <p>
            Based on the currently implemented API functionality, some examples
            are as follows:
          </p>
          <ul>
            <li>
              <code>BTC.GBP.PR.AVG</code>: average BTC/GBP price, calculated
              from all supported exchanges over the last hour.
            </li>
            <li>
              <code>ETH.USD.PR.AVI</code>: average ETH/USD price, calculated
              from all supported exchanges over the last hour, removing outliers
              (extremely high/low values) from the calculation.
            </li>
            <li>
              <code>ETH.USD.PR.AVI.24H</code>: average ETH/USD price, calculated
              from all supported exchanges over the last 24 hours, removing
              outliers (extremely high/low values) from the calculation.
            </li>
            <li>
              <code>COOL.WETH.AD</code>: adhoc request for COOL/WETH pair. Will
              query DEXs for current price and return the mean price.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default OoODataApi;
