import { api } from "services/api";
import { createSlice } from "@reduxjs/toolkit";
import { encrypt, storeLocalStorageData } from "utils/helpers";

const initialState = {
  isShowProjectPopup: false,
  projectEditData: null,
  profileImage: "",
  accountData: null,
  networkType: null,
  copyData: null,
  errorData: null,
  showSubscriptionPopup: false,
  paymentOptionList: null,
  isSubscription: false,
  globalChainID: 0,
  isDarkMode: false,
  showSubscriptionExpirePopup: false,
  subscriptionEndDate: null,
  remainingDays: 0,
  isOpen: false,
  blockChainPopup: false,
  showNodeInfoPopup: false,
  showNodePausePopup: false,
  isServiceDown: false,
  openFreeTrailPopup: false,
};

const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    setIsShowProjectPopup(state, action) {
      state.isShowProjectPopup = action.payload;
    },
    setProjectEditData(state, action) {
      state.projectEditData = action.payload;
    },
    setProfileImage(state, action) {
      state.profileImage = action.payload;
    },
    setAccountData(state, action) {
      state.accountData = action.payload;
    },
    setRemainingDays(state, action) {
      state.remainingDays = action.payload;
    },
    setNetworkType(state, action) {
      state.networkType = action.payload;
    },
    setCopyData(state, action) {
      state.copyData = action.payload;
    },
    setErrorData(state, action) {
      state.errorData = action.payload;
    },
    setShowSubscriptionPopup(state, action) {
      state.showSubscriptionPopup = action.payload;
    },
    setShowSubscriptionExpirePopup(state, action) {
      state.showSubscriptionExpirePopup = action.payload;
    },
    setSubscriptionEndDate(state, action) {
      state.subscriptionEndDate = action.payload;
    },
    setPaymentOptionList(state, action) {
      state.paymentOptionList = action.payload;
    },
    setIsSubscription(state, action) {
      state.isSubscription = action.payload;
    },
    setGlobalChainID(state, action) {
      state.globalChainID = action.payload;
    },
    setIsDarkMode(state, action) {
      state.isDarkMode = action.payload;
    },
    setIsOpen(state, action) {
      state.isOpen = action.payload;
    },
    setBlockChainPopup(state, action) {
      state.blockChainPopup = action.payload;
    },
    setShowNodeInfoPopup(state, action) {
      state.showNodeInfoPopup = action.payload;
    },
    setIsServiceDown(state, action) {
      state.isServiceDown = action.payload;
    },
    setShowNodePausePopup(state, action) {
      state.showNodePausePopup = action.payload;
    },
    setOpenFreeTrailPopup(state, action) {
      state.openFreeTrailPopup = action.payload;
    },
    resetAllState(state) {
      state.isShowProjectPopup = false;
      state.projectEditData = null;
      state.profileImage = "";
      state.accountData = null;
      state.networkType = null;
      state.copyData = null;
      state.errorData = null;
      state.showSubscriptionPopup = false;
      state.paymentOptionList = null;
      state.isSubscription = false;
      state.globalChainID = 0;
      state.isDarkMode = false;
      state.isOpen = false;
      state.blockChainPopup = false;
      state.isServiceDown = false;
      state.showNodePausePopup = false;
      state.showNodeInfoPopup = false;
      state.remainingDays = 0;
      state.showSubscriptionExpirePopup = false;
      state.subscriptionEndDate = null;
      state.openFreeTrailPopup = false;
    },
  },
});

export const userSignup = (payload) => async (dispatch) => {
  const res = await api.post("/email/register", payload);
  if (res?.status === 201) {
    dispatch(showSuccess(res?.data?.message || "User created successfully!"));
  }
  return await dispatch(handelResponse(res));
};

export const handleVerifyOtpApi = (payload) => async (dispatch) => {
  const res = await api.post("/email/verify", payload);
  if (res?.status === 200) {
    dispatch(showSuccess(res?.data?.message || "OTP Verified successfully!"));
  }
  return await dispatch(handelResponse(res));
};

export const handleResendOtpApi = (payload) => async (dispatch) => {
  const res = await api.post("/email/register/resend", payload);
  if (res?.status === 200) {
    dispatch(showSuccess(res?.data?.message || "OTP Re-Sent successfully!"));
  }
  return await dispatch(handelResponse(res));
};

export const handleLoginApi = (payload) => async (dispatch) => {
  try {
    const res = await api.post("/email/login", payload, {});
    if (res?.status === 200) {
      storeLocalStorageData({ ...res?.data });
    }
    return res;
  } catch (error) {
    return dispatch(handelCatch(error));
  }
};

export const handelResponse = (res) => async (dispatch) => {
  let returnValue = null;
  const status = res?.status;
  switch (status) {
    case 200:
      returnValue = res;
      break;
    case 201:
      returnValue = res;
      break;
    case 204:
      returnValue = {
        status: status,
        data: [],
      };
      break;
    case 400:
      throwError({ message: "Something went wrong!" });
      break;
    default:
      throwError({ message: "Something went wrong!" });
      returnValue = {
        status: status,
        message: "Something went wrong!",
      };
      break;
  }
  return returnValue;
};
export const handelCatch = (error) => async (dispatch) => {
  let status = error?.response?.status;
  let messsage =
    error?.response?.data?.message ||
    error?.response?.data ||
    "Something went wrong!";
  let returnCatch = {
    status: status,
    messsage: messsage,
  };
  if (status === 401) {
    dispatch(throwError("Session is expired"));
    dispatch(setAccountData(null));
    // dispatch(setShowSubscriptionPopup(false));
    dispatch(resetAllState(null));
    localStorage.removeItem("accountData");
    localStorage.removeItem("theme");
  } else if (status === 403) {
    messsage =
      error?.response?.data?.message ||
      error?.response?.data ||
      "Something went wrong!";
    dispatch(
      setErrorData({
        show: true,
        message: messsage,
        type: "danger",
      })
    );
  } else if (status === 409) {
    messsage =
      error?.response?.data?.message ||
      error?.response?.data ||
      "Something went wrong!";
    dispatch(
      setErrorData({
        show: true,
        message: messsage,
        type: "danger",
      })
    );
  } else if (status === 404) {
    dispatch(
      setErrorData({
        show: true,
        message: messsage,
        type: "danger",
      })
    );
  } else if (status === 400) {
    messsage = error?.response?.data?.message || "Something went wrong!";
    dispatch(
      setErrorData({
        show: true,
        message: messsage,
        type: "danger",
      })
    );
  } else if (status === 500) {
    messsage = error?.response?.data?.message || "Something went wrong!";
    dispatch(
      setErrorData({
        show: true,
        message: messsage,
        type: "danger",
      })
    );
  } else if (status === 503) {
    messsage = error?.response?.data?.message || "Something went wrong!";
    dispatch(
      setErrorData({
        show: true,
        message: messsage,
        type: "danger",
      })
    );
  } else {
    dispatch(
      setErrorData({
        show: true,
        message: messsage,
        type: "danger",
      })
    );
  }
  return returnCatch;
};
export const showSuccess = (message) => async (dispatch) => {
  dispatch(
    setErrorData({
      show: true,
      message: message,
      type: "success",
    })
  );
};
export const throwError = (message) => async (dispatch) => {
  let newMessage = message;
  newMessage = message || "Something went wrong!";
  dispatch(
    setErrorData({
      show: true,
      message: newMessage,
      type: "danger",
    })
  );
};

export const {
  setIsShowProjectPopup,
  setProjectEditData,
  setProfileImage,
  setAccountData,
  setNetworkType,
  setCopyData,
  setErrorData,
  setShowSubscriptionPopup,
  setPaymentOptionList,
  setIsSubscription,
  resetAllState,
  setGlobalChainID,
  setIsDarkMode,
  setShowSubscriptionExpirePopup,
  setSubscriptionEndDate,
  setRemainingDays,
  setIsOpen,
  setBlockChainPopup,
  setShowNodeInfoPopup,
  setIsServiceDown,
  setShowNodePausePopup,
  setOpenFreeTrailPopup,
} = globalSlice.actions;

export default globalSlice.reducer;
