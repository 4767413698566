// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#modal-container {
  position: relative;
  border-radius: 6px;
  padding: 19px 0px 24px 0px;
}
#modal-container .modal-close-button {
  position: absolute;
  right: 18px;
  top: 20px;
  font-size: 20px;
  color: #a9a9a9;
}

.modal-dialog {
  width: fit-content !important;
}

.modal-content {
  border-radius: 20px;
}

.modal-block-custom {
  padding-right: unset !important;
}

.dark {
  background: var(--2b4e);
}`, "",{"version":3,"sources":["webpack://./src/components/layouts/Modal/Modal.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,kBAAA;EACA,0BAAA;AACF;AAAE;EACE,kBAAA;EACA,WAAA;EACA,SAAA;EACA,eAAA;EACA,cAAA;AAEJ;;AACA;EACE,6BAAA;AAEF;;AAAA;EACE,mBAAA;AAGF;;AADA;EACE,+BAAA;AAIF;;AAFA;EACE,uBAAA;AAKF","sourcesContent":["#modal-container {\r\n  position: relative;\r\n  border-radius: 6px;\r\n  padding: 19px 0px 24px 0px;\r\n  .modal-close-button {\r\n    position: absolute;\r\n    right: 18px;\r\n    top: 20px;\r\n    font-size: 20px;\r\n    color: #a9a9a9;\r\n  }\r\n}\r\n.modal-dialog {\r\n  width: fit-content !important;\r\n}\r\n.modal-content {\r\n  border-radius: 20px;\r\n}\r\n.modal-block-custom {\r\n  padding-right: unset !important;\r\n}\r\n.dark {\r\n  background: var(--2b4e);\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
