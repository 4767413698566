import { createBrowserRouter } from "react-router-dom";

// routes
import AppRoute from "routes/AppRoute";
import AuthRoute from "routes/AuthRoute";

// ==============================|| ROUTING RENDER ||============================== //
const router = createBrowserRouter([AppRoute, AuthRoute]);

export default router;
