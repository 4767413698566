import { useRef } from "react";
import Cropper from "react-cropper";
import { Button, Modal } from "components";
import "cropperjs/dist/cropper.css";
import { useSelector } from "react-redux";

const ImageCropper = ({ id, fileURL, handelCropImage, onHide }) => {
  const cropperRef = useRef();
  const reduxData = useSelector((state) => state.global);
  const { isDarkMode } = reduxData || {};

  const dataURLtoFile = async (dataurl, filename) => {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  };

  const getCropData = async () => {
    if (typeof cropperRef.current?.cropper !== "undefined") {
      let bString = cropperRef.current?.cropper.getCroppedCanvas().toDataURL();
      let date = new Date();
      let getfile = await dataURLtoFile(bString, `${date.getTime()}.png`);
      handelCropImage({
        target: {
          id: id,
          value: getfile,
          urlString: bString,
        },
      });

      onHide();
    }
  };

  return (
    <Modal onHide={onHide} width="650px" title="Crop Selected Profile" isDarkMode={isDarkMode}>
      <Cropper
        src={fileURL}
        style={{
          height: 400,
          width: "100%",
          paddingLeft: "10px",
          paddingRight: "10px",
          display: "flex",
        }}
        aspectRatio={1}
        guides={false}
        ref={cropperRef}
        minCropBoxHeight={150}
        minCropBoxWidth={150}
      />
      <div className="d-flex justify-content-center mt-3">
        <Button
          hideAnimation
          btnText="Crop Image"
          btnStyle="PD"
          className=""
          onClick={getCropData}
        />
      </div>
    </Modal>
  );
};
export default ImageCropper;
