import Dashboard from "pages/Dashboard";
import MainLayout from "layout/MainLayout";
import Unode from "pages/Unode";
import OooDashboard from "pages/OoO/Dashboard";
import VORIntegration from "pages/VOR/Integration";
import VORDashboard from "pages/VOR/Dashboard";
import FundJsCodeSnippets from "pages/Fundjs/CodeSnippets";
import FundJsDocumentation from "pages/Fundjs/Dashboard";
import TestNetFaucetDashboard from "pages/TestNetFaucet/Dashboard";
import BeaconDashboard from "pages/BEACON/Dashboard";
import BeaconIntegration from "pages/BEACON/Integration";
import BeaconCodeSnippets from "pages/BEACON/CodeSnippets";
import BeaconXFundEmissions from "pages/BEACON/XFundEmissions";
import { Navigate } from "react-router-dom";
import SmartContracts from "pages/OoO/SmartContracts";
import OoOQuickStart from "pages/OoO/OoOQuickStart";
import OoOImplementation from "pages/OoO/OoOImplementation";
import OoOInitialisation from "pages/OoO/OoOInitialisation";
import OoODataApi from "pages/OoO/OoODataApi";
import PaymentStream from "pages/PaymentStream";
import WrkChain from "pages/WrkChain";

const isAuth = () => {
  const accountData = localStorage.getItem("accountData");
  return accountData;
};
// Protected Route Component
const ProtectedRoute = ({ children }) => {
  return isAuth() ? children : <Navigate to="/login" replace />;
};

const AppRoute = {
  path: "/",
  element: (
    <ProtectedRoute>
      <MainLayout />
    </ProtectedRoute>
  ),
  children: [
    {
      path: "/",
      element: <Navigate to="/main-dashboard" replace />,
    },
    {
      path: "/main-dashboard",
      element: <Dashboard />,
    },
    {
      path: "unode",
      children: [
        {
          path: "dashboard",
          element: <Unode />,
        },
      ],
    },
    {
      path: "ooo",
      children: [
        {
          path: "dashboard",
          element: <OooDashboard />,
        },
        {
          path: "smart-ontracts",
          element: <SmartContracts />,
        },
        {
          path: "ooo-quickstart",
          element: <OoOQuickStart />,
        },
        {
          path: "ooo-implementation",
          element: <OoOImplementation />,
        },
        {
          path: "ooo-initialisation",
          element: <OoOInitialisation />,
        },
        {
          path: "ooo-data-api",
          element: <OoODataApi />,
        },
      ],
    },
    {
      path: "vor",
      children: [
        {
          path: "integration",
          element: <VORIntegration />,
        },
        {
          path: "dashboard",
          element: <VORDashboard />,
        },
      ],
    },
    {
      path: "payment-streams",
      element: <PaymentStream />,
    },
    {
      path: "fundjs",
      children: [
        {
          path: "dashboard",
          element: <FundJsDocumentation />,
        },
        {
          path: "code-snippets",
          element: <FundJsCodeSnippets />,
        },
      ],
    },
    {
      path: "testnet-faucet",
      children: [
        {
          path: "dashboard",
          element: <TestNetFaucetDashboard />,
        },
      ],
    },
    {
      path: "wrk-chain",
      element: <WrkChain />,
    },
    {
      path: "beacon",
      children: [
        {
          path: "dashboard",
          element: <BeaconDashboard />,
        },
        {
          path: "integration",
          element: <BeaconIntegration />,
        },
        {
          path: "code-snippets",
          element: <BeaconCodeSnippets />,
        },
        {
          path: "xfund-emissions",
          element: <BeaconXFundEmissions />,
        },
      ],
    },
    {
      path: "*",
      element: <Dashboard />,
    },
  ],
};

export default AppRoute;
