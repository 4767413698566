import { Roundedloader } from "components";
import "./Button.scss";

const Button = ({
  btnText,
  btnStyle,
  btnType,
  onClick,
  className,
  rightIcon,
  leftIcon,
  disabled,
  loading,
  leftIconStyle,
  rightIconStyle,
}) => {
  return (
    <div id="button-container">
      <button
        className={`btn-block ${btnStyle ? btnStyle : "PD"} ${
          className ? className : ""
        }`}
        onClick={onClick}
        type={btnType || "button"}
        disabled={disabled}
      >
        <span className="d-flex align-items-center justify-content-center gap-2">
          {leftIcon && (
            <span className="h-20 d-flex">
              <img
                src={leftIcon}
                alt="left-icon"
                className={`fit-image`}
                style={leftIconStyle}
              />
            </span>
          )}
          {btnText && <span>{btnText}</span>}
          {rightIcon && (
            <span className="h-20 d-flex">
              <img
                src={rightIcon}
                alt="right-icon"
                className="fit-image"
                style={rightIconStyle}
              />
            </span>
          )}
          {loading && (
            <span>
              <Roundedloader
                type={["PD", "RD"].includes(btnStyle) ? "L" : "D"}
              />
            </span>
          )}
        </span>
      </button>
    </div>
  );
};

export default Button;
