// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#roundedloader-container .spinner-border {
  --bs-spinner-border-width: 0.2em;
}
#roundedloader-container .spinner-border-sm {
  --bs-spinner-border-width: 0.13em;
}`, "",{"version":3,"sources":["webpack://./src/components/layouts/Roundedloader/Roundedloader.scss"],"names":[],"mappings":"AACE;EACE,gCAAA;AAAJ;AAEE;EACE,iCAAA;AAAJ","sourcesContent":["#roundedloader-container {\r\n  .spinner-border {\r\n    --bs-spinner-border-width: 0.2em;\r\n  }\r\n  .spinner-border-sm {\r\n    --bs-spinner-border-width: 0.13em;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
