import { useSelector } from "react-redux";
import "./WrkChain.scss";

const WrkChain = () => {
  const { isDarkMode } = useSelector((state) => state.global) || {};
  const textColorClass = isDarkMode ? "text-white" : "text-black";
  return (
    <div
      id="wrkchain-container"
      className={`f-center ${textColorClass} text-26-500`}
    >
      Comming Soon...
    </div>
  );
};

export default WrkChain;
