// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#navbar-container .login-page.dark-mode {
  color: #e0e0e0;
}
#navbar-container .login-page.dark-mode .login-container {
  background-color: #1a1a1a;
}
#navbar-container .login-page .logo {
  width: 120px;
  height: auto;
}
#navbar-container .login-page .dark-logo {
  filter: none;
}
#navbar-container .login-page .light-logo {
  filter: invert(1) brightness(2);
}`, "",{"version":3,"sources":["webpack://./src/layout/MinimalLayout/NavBar/NavBar.scss"],"names":[],"mappings":"AAEI;EACE,cAAA;AADN;AAGM;EACE,yBAAA;AADR;AAII;EACE,YAAA;EACA,YAAA;AAFN;AAKI;EACE,YAAA;AAHN;AAMI;EACE,+BAAA;AAJN","sourcesContent":["#navbar-container {\r\n  .login-page {\r\n    &.dark-mode {\r\n      color: #e0e0e0;\r\n\r\n      .login-container {\r\n        background-color: #1a1a1a;\r\n      }\r\n    }\r\n    .logo {\r\n      width: 120px;\r\n      height: auto;\r\n    }\r\n\r\n    .dark-logo {\r\n      filter: none;\r\n    }\r\n\r\n    .light-logo {\r\n      filter: invert(1) brightness(2);\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
