import { useSelector } from "react-redux";
import "./PaymentStream.scss";
import { Row, Col, Card, Form, InputGroup } from "react-bootstrap";
import { Button, Dropdown, Textinput } from "components";
import { useState } from "react";
import { icons } from "utils/constants";

const PaymentStream = () => {
  const { isDarkMode } = useSelector((state) => state.global) || {};
  const textColorClass = isDarkMode ? "text-white" : "text-black";
  const scrollClass = isDarkMode ? "custom-scroll-dark" : "custom-scroll";
  const [tab, settab] = useState(0);
  const [connected, setConnected] = useState(false);
  const [selectedValue, setselectedValue] = useState("Unification");
  const [selectedTime, setselectedTime] = useState("Month");

  return (
    <div id="paymentstream-container" className={`cp-38 ${scrollClass}`}>
      <h2 className={`text-center mb-5 ${textColorClass}`}>Payment Streams</h2>
      <Row className="justify-content-center mb-4">
        <Col md={10} lg={8} className="d-flex align-items-center">
          <Card className={`w-100 bg-transparent border-0  ${textColorClass}`}>
            <Row className={`${connected ? "" : "f-center"} `}>
              <Col xs={12} md={5} className="mb-2 d-flex flex-column gap-2">
                <div>
                  <Dropdown
                    isDarkMode={isDarkMode}
                    options={[
                      {
                        id: 1,
                        label: "Unification",
                        icon: icons.unificationIcon,
                      },
                      {
                        id: 2,
                        label: "Unification Testnet",
                        icon: icons.unificationIcon,
                      },
                    ]}
                    placeholder="Select Network"
                    onChange={(e) => {
                      setselectedValue(e.target.value);
                    }}
                    value={selectedValue}
                    defaultValue={{
                      id: 1,
                      label: "Unification",
                      icon: icons.unificationIcon,
                    }}
                  />
                </div>
                <Card
                  className={`text-center py-2 bg-transparent  ${textColorClass}`}
                >
                  <Card.Body>
                    {connected && (
                      <Card.Text>
                        <div className="cmb-10">Wallet1</div>
                        <div className="address">und1nkg0…sdsaeg8</div>
                      </Card.Text>
                    )}
                    <div className="w-100 f-center">
                      <Button
                        btnText={`${
                          connected ? "My Wallet" : "Connect Wallet"
                        }`}
                        btnStyle="LG"
                        className="w-200 h-40 br-4"
                        onClick={() => {
                          if (connected) {
                            //
                          } else {
                            setConnected(true);
                          }
                        }}
                      />
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              {connected && (
                <Col xs={12} md={7} className="mb-2">
                  <Card
                    className={`p-3 bg-transparent d-flex h-100 flex-column ${textColorClass}`}
                  >
                    <h4 className="fw-bold">Balance</h4>
                    <div className="d-flex align-items-center justify-content-center flex-grow-1 gap-2">
                      <span className={`fs-1 fw-bold ${textColorClass}`}>
                        0
                      </span>
                      <span className={`fs-5 ${textColorClass}`}>FUND</span>
                    </div>
                    <p className={`text-start mb-0 ${textColorClass}`}>
                      Get TestNet FUND from the{" "}
                      <a
                        href="https://faucet-testnet.unification.io/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Faucet
                      </a>
                    </p>
                  </Card>
                </Col>
              )}
            </Row>
          </Card>
        </Col>
      </Row>
      {!connected && (
        <div className={`text-center cmt-20 cmb-20 ${textColorClass}`}>
          Please connect to your wallet to interact with Payment Streams.
        </div>
      )}

      {connected && (
        <Row className="justify-content-center mb-4">
          <Col md={10} lg={8}>
            <div className="d-flex justify-content-between align-items-center">
              <Button
                className="rounded-pill h-40"
                btnText="Create Stream"
                onClick={() => settab(0)}
                btnStyle={tab === 0 ? "MD" : ""}
              />
              <div className="d-flex gap-2">
                <Button
                  className="rounded-pill h-40"
                  btnText="As Sender (0)"
                  onClick={() => settab(1)}
                  btnStyle={tab === 1 ? "MD" : ""}
                />
                <Button
                  className="rounded-pill h-40"
                  btnText="As Receiver (0)"
                  onClick={() => settab(2)}
                  btnStyle={tab === 2 ? "MD" : ""}
                />
              </div>
            </div>
          </Col>
        </Row>
      )}

      {tab === 0 && connected && (
        <Row className="justify-content-center">
          <Col md={10} lg={8}>
            <Card className={`p-3 bg-transparent  ${textColorClass}`}>
              <h5 className="fw-bold mb-3">1. Calculate Flow Rate</h5>
              <Form>
                <Row className="gy-3">
                  <Col xs={12} md={4}>
                    <Textinput
                      placeholder="Enter Amount"
                      label="Fund Amount"
                      type="number"
                      className="h-40"
                      isDarkMode={isDarkMode}
                    />
                  </Col>
                  <Col xs={6} md={4}>
                    <Form.Group>
                      <div className="d-flex gap-3">
                        <div>
                          <Textinput
                            placeholder="Enter Period"
                            label="Every"
                            type="number"
                            className="h-40"
                            isDarkMode={isDarkMode}
                          />
                        </div>
                        <div className="cmt-32">
                          <Dropdown
                            isDarkMode={isDarkMode}
                            options={[
                              {
                                id: 1,
                                label: "Minute",
                              },
                              {
                                id: 2,
                                label: "Hour",
                              },
                              {
                                id: 3,
                                label: "Day",
                              },
                              {
                                id: 4,
                                label: "Weak",
                              },
                              {
                                id: 5,
                                label: "Month",
                              },
                              {
                                id: 6,
                                label: "Year",
                              },
                            ]}
                            placeholder=""
                            onChange={(e) => {
                              setselectedTime(e.target.value);
                            }}
                            value={selectedTime}
                            defaultValue={{
                              id: 5,
                              label: "Month",
                            }}
                          />
                        </div>
                      </div>
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={4}>
                    <Textinput
                      placeholder="Enter wallet address"
                      label="Receiver Wallet Address"
                      className="h-40"
                      isDarkMode={isDarkMode}
                    />
                  </Col>
                </Row>
                <div className="f-center justify-content-end mt-3">
                  <Button className="h-40" btnText="Calculate" />
                </div>
              </Form>
            </Card>
          </Col>
        </Row>
      )}
      {tab === 1 && connected && (
        <Row className="justify-content-center">
          <Col md={10} lg={8}>
            <Card className={`p-3 bg-transparent  ${textColorClass}`}>
              <div className="fa-center gap-3 cmb-10">
                <div>Streams as Sender</div>
                <div>
                  <Button btnText="Refresh" className="h-30 cps-5 cpe-5" />
                </div>
              </div>
              <div>No streams</div>
            </Card>
          </Col>
        </Row>
      )}
      {tab === 2 && connected && (
        <Row className="justify-content-center">
          <Col md={10} lg={8}>
            <Card className={`p-3 bg-transparent  ${textColorClass}`}>
              <div className="fa-center gap-3 cmb-10">
                <div>Streams as Receiver</div>
                <div>
                  <Button btnText="Refresh" className="h-30 cps-5 cpe-5" />
                </div>
              </div>
              <div>No streams</div>
            </Card>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default PaymentStream;
