import { useSelector } from "react-redux";
import "./OoOImplementation.scss";
import SyntaxHighlighter from "react-syntax-highlighter";
import { atomOneDark } from "react-syntax-highlighter/dist/esm/styles/hljs";

const OoOImplementation = () => {
  const { isDarkMode } = useSelector((state) => state.global) || {};
  const textColorClass = isDarkMode ? "text-white" : "text-black";
  const scrollClass = isDarkMode ? "custom-scroll-dark" : "custom-scroll";
  const syntaxStyle = atomOneDark;

  const handleScrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div id="oooimplementation-container" className={`cp-38 ${scrollClass}`}>
      <div className={`contract-addresses container ${textColorClass}`}>
        <h1 id="ooo-quickstart" className="mb-3">
          <a href="#ooo-quickstart" className="header-anchor">
            #
          </a>
          OoO Implementation & Integration Guide
        </h1>
        <p>
          This guide will walk you through all the necessary steps to get a
          fully working (albeit simple) smart contract, which can obtain Price
          data from the Finchains Oracle of Oracles.
        </p>
        <p>
          This guide will result in something similar to the{" "}
          <a href="https://github.com/unification-com/data-consumer-demo">
            Data Consumer Demo
          </a>
          .
        </p>
        <p>
          The instructions will outline the steps required to deploy on the
          Rinkeby testnet, but will also work with mainnet.
        </p>
        <div
          className={`${
            isDarkMode ? "redBlockD" : "redBlock"
          } custom-block tip`}
        >
          <strong className="custom-block-title">IMPORTANT</strong>
          <p>
            You do not need to implement or deploy the <code>Router.sol</code>{" "}
            smart contract.
          </p>
          <div>
            This is a smart contract deployed and maintained by the Unification
            Foundation and is the core of the xFUND Router network. Your smart
            contract will only import and build on the{" "}
            <code>ConsumerBase.sol</code> base smart contract, which in turn
            interacts with the <code>Router</code> smart contract.
          </div>
        </div>
        <div className="mt-5 mb-3">
          <h3 className="border-bottom border-1 pb-2">
            <a href="#ooo-contract-addresses" className="h-anchor">
              #
            </a>
            1. Initialise your project & install dependencies
          </h3>
          <div className="note mt-3">
            <p>
              <strong>Note:</strong> If you are integrating into an existing
              project, or are already familiar with initialising NodeJS and
              Truffle projects, you can skip this section and move on to{" "}
              <strong>
                <span
                  style={{ cursor: "pointer", color: "blue" }}
                  onClick={() => handleScrollToSection("section-1-2")}
                >
                  1.2. Install the required dependencies
                </span>
              </strong>
              .
            </p>
          </div>
          <h4>1.1. Initialise your project</h4>
          <p>
            Create the directory, and initialise NPM - accept the defaults for
            the <code>npm init</code> command:
          </p>
          <SyntaxHighlighter language="powershell" style={syntaxStyle}>
            {`mkdir consumer_demo && cd consumer_demo
npm init`}
          </SyntaxHighlighter>
          <p>Install Truffle, and initialise the Truffle project:</p>
          <SyntaxHighlighter language="powershell" style={syntaxStyle}>
            {`npm install truffle --save-dev
npx truffle init`}
          </SyntaxHighlighter>
          <p>You should now have a project structure as follows:</p>
          <SyntaxHighlighter language="powershell" style={syntaxStyle}>
            {`contracts
migrations
node_modules
package.json
package-lock.json
test
truffle-config.js`}
          </SyntaxHighlighter>
          <h4 id="section-1-2">1.2. Install the required dependencies</h4>
          <p>
            We need to install some dependencies for the project -{" "}
            <code>@unification-com/xfund-router</code>:
          </p>
          <SyntaxHighlighter language="powershell" style={syntaxStyle}>
            {`npm install @unification-com/xfund-router`}
          </SyntaxHighlighter>
          <p>
            If you don't have them installed already, we also need{" "}
            <code>dotenv</code> and <code>@truffle/hdwallet-provider</code>,
            both of which will be used to aid deployment and interaction later:
          </p>
          <SyntaxHighlighter language="powershell" style={syntaxStyle}>
            {`npm install dotenv
npm install @truffle/hdwallet-provider --save-dev`}
          </SyntaxHighlighter>
        </div>
        <div className="mt-5 mb-3">
          <h3 className="border-bottom border-1 pb-2">
            <a href="#ooo-create-contract" className="h-anchor">
              #
            </a>
            2. Create the initial Contract
          </h3>
          <p>
            We'll start with a simple contract structure. With a text editor,
            create
            <code>contracts/MyDataConsumer.sol</code> with the following
            contents:
          </p>
          <SyntaxHighlighter language="javascript" style={syntaxStyle}>
            {`// SPDX-License-Identifier: MIT

pragma solidity >=0.7.0 <0.8.0;

contract MyDataConsumer {

    uint256 public price;

    constructor() {
        price = 0;
    }
}`}
          </SyntaxHighlighter>
          <p>
            This will be the basis for adding the OoO functionality from the
            xfund-router libraries.
          </p>
          <p>
            The <code>price</code> variable is what we would like to be updated
            by OoO when we request data.
          </p>
          <h4 id="section-2-1">
            2.1 Import the xfund-router Library contracts
          </h4>
          <p>
            Next, we need to import the <code>ConsumerBase.sol</code> smart
            contract, which interacts with the <code>Router.sol</code> smart
            contract (which has been deployed and is maintained by the
            Unification Foundation). The <code>ConsumerBase.sol</code> smart
            contract contains required functions for interacting with the
            system. You only need to define a couple of functions in your own
            smart contract in order to use the OoO system, which override or
            extend the underlying <code>ConsumerBase</code> functions.
          </p>
          <div className="note">
            <p>
              <strong>Note</strong>
            </p>
            <div>
              You can view the functions implemented by{" "}
              <code>ConsumerBase.sol</code> in the{" "}
              <a href="#">Data Consumer smart contract API documentation</a>.
              There are some additional helper functions which can be wrapped in
              functions in your own smart contract.
            </div>
          </div>
          <p>
            First, import the <code>ConsumerBase.sol</code> smart contract.
            After the pragma definition, add:
          </p>
          <SyntaxHighlighter language="javascript" style={syntaxStyle}>
            {`import "@unification-com/xfund-router/contracts/lib/ConsumerBase.sol";`}
          </SyntaxHighlighter>
          <p>
            Then, edit the contract definition, so that it extends{" "}
            <code>ConsumerBase.sol</code>:
          </p>
          <SyntaxHighlighter language="javascript" style={syntaxStyle}>
            {`contract MyDataConsumer is ConsumerBase {`}
          </SyntaxHighlighter>
          <p>
            Finally, modify the constructor function to call the{" "}
            <code>ConsumerBase.sol</code>'s constructor, passing the contract
            addresses for Router and xFUND:
          </p>
          <SyntaxHighlighter language="javascript" style={syntaxStyle}>
            {`constructor(address _router, address _xfund)
    public ConsumerBase(_router, _xfund) {
        price = 0;
    }`}
          </SyntaxHighlighter>
          <p>
            The full <code>MyDataConsumer.sol</code> contract code should now
            look like this:
          </p>
          <SyntaxHighlighter language="javascript" style={syntaxStyle}>
            {`// SPDX-License-Identifier: MIT

pragma solidity ^0.6.0;

import "@unification-com/xfund-router/contracts/lib/ConsumerBase.sol";

contract MyDataConsumer is ConsumerBase {

    uint256 public price;

    constructor(address _router, address _xfund)
    public ConsumerBase(_router, _xfund) {
        price = 0;
    }
}`}
          </SyntaxHighlighter>
        </div>
        <div className="mt-5 mb-3">
          <h3 className="border-bottom border-1 pb-2">
            <a href="#ooo-create-contract" className="h-anchor">
              #
            </a>
            3. Define the required <code>receiveData</code> smart contract
            function
          </h3>
          <p>
            <code>receiveData</code> will be called by the Data Provider
            (indirectly - it is actually proxied via the <code>Router</code>{" "}
            smart contract) in order to fulfil a data request and send data to
            our smart contract. It should override the abstract{" "}
            <code>receiveData</code> function defined in the{" "}
            <code>ConsumerBase.sol</code>
            base smart contract, and must have the following parameters:
          </p>
          <ul>
            <li>
              <code>uint256 _price</code> - the price data the provider is
              sending.
            </li>
            <li>
              <code>bytes32 _requestId</code> - the ID of the request being
              fulfilled. This is passed in case your contract needs to do some
              further processing with the request ID.
            </li>
          </ul>
          <p>
            Add the following function definition to your{" "}
            <code>MyDataConsumer.sol</code> contract:
          </p>
          <SyntaxHighlighter language="javascript" style={syntaxStyle}>
            {`function receiveData(uint256 _price, bytes32 _requestId)
    internal override {
        price = _price;
    }`}
          </SyntaxHighlighter>
          <p>You can also optionally add an event to the function:</p>
          <p>Define a new event in the contract:</p>
          <SyntaxHighlighter language="javascript" style={syntaxStyle}>
            {`contract MyDataConsumer is ConsumerBase {
    ...
    event GotSomeData(bytes32 requestId, uint256 price);`}
          </SyntaxHighlighter>
          <p>and emit within the receiveData function:</p>
          <SyntaxHighlighter language="javascript" style={syntaxStyle}>
            {` function receiveData( ...
        ...
        emit GotSomeData(_requestId, _price);`}
          </SyntaxHighlighter>
        </div>
        <div className="mt-5 mb-3">
          <h3 className="border-bottom border-1 pb-2">
            <a href="#ooo-create-contract" className="h-anchor">
              #
            </a>
            4. Define a function to initialise a data request
          </h3>
          <p>
            Create a function to request data, which calls{" "}
            <code>_requestData</code> from <code>ConsumerBase.sol</code>:
          </p>
          <SyntaxHighlighter language="javascript" style={syntaxStyle}>
            {`function getData(address _provider, uint256 _fee, bytes32 _data) external returns (bytes32) {
    return _requestData(_provider, _fee, _data);
}`}
          </SyntaxHighlighter>
        </div>
        <div className="mt-5 mb-3">
          <h3 className="border-bottom border-1 pb-2">
            <a href="#ooo-create-contract" className="h-anchor">
              #
            </a>
            5. Add a function to allow <code>Router</code> to transfer fees
          </h3>
          <p>
            Finally, you'll need a function that calls <code>ConsumerBase</code>
            's <code>_increaseRouterAllowance</code> function. This function
            will increase the <code>Routers</code>s xFUND allowance, allowing it
            to pay data request fees on behalf of your smart contract:
          </p>
          <SyntaxHighlighter language="javascript" style={syntaxStyle}>
            {`function increaseRouterAllowance(uint256 _amount) external {
    require(_increaseRouterAllowance(_amount));
}`}
          </SyntaxHighlighter>
          <p>
            <strong>Note:</strong> This function should be protected using a
            library like OpenZeppelin's
            <code>Ownable</code> and thave the <code>onlyOwner</code> modifier
            applied such that only your contract's owner can all the function!
          </p>
          <p>
            The final <code>MyDataConsumer.sol</code> code should now look
            something like this:
          </p>
          <SyntaxHighlighter language="javascript" style={syntaxStyle}>
            {`// SPDX-License-Identifier: MIT

pragma solidity ^0.6.0;

import "@unification-com/xfund-router/contracts/lib/ConsumerBase.sol";

contract MyDataConsumer is ConsumerBase {

    uint256 public price;

    event GotSomeData(bytes32 requestId, uint256 price);

    constructor(address _router, address _xfund)
    public ConsumerBase(_router, _xfund) {
        price = 0;
    }
    
    // Optionally protect with a modifier to limit who can call
    function getData(address _provider, uint256 _fee, bytes32 _data) external returns (bytes32) {
        return _requestData(_provider, _fee, _data);
    }
    
    // Todo - protect with a modifier to limit who can call!
    function increaseRouterAllowance(uint256 _amount) external {
        require(_increaseRouterAllowance(_amount));
    }

    // ConsumerBase ensures only the Router can call this
    function receiveData(uint256 _price, bytes32 _requestId)
    internal override {
        price = _price;
        // optionally emit an event to the logs
        emit GotSomeData(_requestId, _price);
    }
}`}
          </SyntaxHighlighter>
          <p>
            <strong>Finally, compile your contract:</strong>
          </p>
          <SyntaxHighlighter language="javascript" style={syntaxStyle}>
            {`npx truffle compile`}
          </SyntaxHighlighter>
        </div>
        <div className="mt-5 mb-3">
          <h3 className="border-bottom border-1 pb-2">
            <a href="#setup-env-truffle" className="h-anchor">
              #
            </a>
            6. Set up the deployment <code>.env</code> and{" "}
            <code>truffle-config.js</code>
          </h3>
          <p>
            Ensure that you have:
            <ol>
              <li>
                An{" "}
                <a
                  href="https://infura.io/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Infura
                </a>{" "}
                account and API key.
              </li>
              <li>
                A test wallet private key and address with{" "}
                <a
                  href="https://www.alchemy.com/faucets/ethereum-sepolia"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Test ETH on Sepolia
                </a>{" "}
                testnet.
              </li>
            </ol>
          </p>
          <h4>6.1 .env</h4>
          <div className="note">
            <p>
              <strong>Note</strong>
            </p>
            <div>
              See Contract Addresses for the latest <strong>Rinkeby</strong>{" "}
              contract address required for the <code>ROUTER_ADDRESS</code> and{" "}
              <code>XFUND_ADDRESS</code>
              variables.
            </div>
          </div>
          <p>
            Create a <code>.env</code> file in the root of your project with the
            following variables and set each value accordingly:
          </p>
          <SyntaxHighlighter language="plaintext" style={syntaxStyle}>
            {`# Private key for wallet used to deploy. This will be the contract owner
# Most functions in ConsumerBase.sol can only be called by the owner
ETH_PKEY=
# Infura API key - used for deployment
INFURA_PROJECT_ID=
# Contract address of the xFUND Router
ROUTER_ADDRESS=
# Contract address of xFUND
XFUND_ADDRESS=`}
          </SyntaxHighlighter>
          <h4>6.2 truffle-config.js</h4>
          <p>
            Edit the <code>truffle-config.js</code> file in the root of your
            project as follows:
          </p>
          <SyntaxHighlighter language="javascript" style={syntaxStyle}>
            {`require("dotenv").config();
const HDWalletProvider = require("@truffle/hdwallet-provider");

const {
  ETH_PKEY,
  INFURA_PROJECT_ID,
} = process.env;

module.exports = {
  networks: {
    develop: {
      host: "127.0.0.1",
      port: 8545,
      network_id: "*",
    },
    sepolia: {
      provider: () =>
        new HDWalletProvider({
          privateKeys: [ETH_PKEY],
          providerOrUrl: \`https://sepolia.infura.io/v3/\${INFURA_PROJECT_ID}\`
        }),
      network_id: "5",
      gas: 10000000,
      gasPrice: 10000000000,
      skipDryRun: true,
    }
  },
  compilers: {
    solc: {
      version: "0.6.12",
      settings: {
        optimizer: {
          enabled: true,
          runs: 200
        }
      }
    }
  }
};`}
          </SyntaxHighlighter>
        </div>
        <div className="mt-5 mb-3">
          <h3 className="border-bottom border-1 pb-2">
            <a href="#setup-migrations" className="h-anchor">
              #
            </a>
            7. Set up the Truffle migrations scripts
          </h3>
          <p>
            Create the following Truffle migration script in{" "}
            <code>migrations/2_deploy.js</code>:
          </p>
          <SyntaxHighlighter language="javascript" style={syntaxStyle}>
            {`require("dotenv").config();
const MyDataConsumer = artifacts.require("MyDataConsumer");

const { ROUTER_ADDRESS, XFUND_ADDRESS } = process.env;

module.exports = function(deployer) {
  deployer.deploy(MyDataConsumer, ROUTER_ADDRESS, XFUND_ADDRESS);
};`}
          </SyntaxHighlighter>
          <p>This will deploy your contract with the required parameters.</p>
        </div>
        <div className="mt-5 mb-3">
          <h3 className="border-bottom border-1 pb-2">
            <a href="#deploy-contract" className="h-anchor">
              #
            </a>
            8. Deploy your contract
          </h3>
          <p>Finally, deploy your contract with the following command:</p>
          <SyntaxHighlighter language="javascript" style={syntaxStyle}>
            {`npx truffle migrate --network=sepolia`}
          </SyntaxHighlighter>
          <p>
            That's it! You're now ready to initialise and interact with your OoO
            enabled smart contract.
          </p>
          <p>
            <strong>On to interaction.</strong>
          </p>
        </div>
      </div>
    </div>
  );
};

export default OoOImplementation;
