import CryptoJS from "crypto-js";

const ENCRYPTION_KEY = CryptoJS.enc.Utf8.parse(
  process.env.REACT_APP_ENCRYPTION_KEY
);
const ENCRYPTION_IV = CryptoJS.enc.Utf8.parse(
  process.env.REACT_APP_ENCRYPTION_IV
);

export const encrypt = (data) => {
  const phrase = JSON.stringify(data);
  const encrypted = CryptoJS.AES.encrypt(phrase, ENCRYPTION_KEY, {
    iv: ENCRYPTION_IV,
    padding: CryptoJS.pad.Pkcs7,
    mode: CryptoJS.mode.CBC,
  });
  const returnData = encrypted.toString();
  return returnData;
};

export const decrypt = (encryptedData) => {
  let returnData = {};
  try {
    var plaintextData = CryptoJS.AES.decrypt(encryptedData, ENCRYPTION_KEY, {
      iv: ENCRYPTION_IV,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
    let decryped = plaintextData.toString(CryptoJS.enc.Utf8);

    if (decryped) {
      returnData = JSON.parse(decryped);
    }
  } catch (error) {
    //
  }
  return returnData;
};

// Store data in local storage with encryption form
export const storeLocalStorageData = (newData) => {
  const decryptData = decrypt(localStorage.accountData || {});
  localStorage.accountData = encrypt({ ...decryptData, ...newData });
};

// Get data from local storage which return decrypt data
export function getDataFromLocalStorage(key = "") {
  let returnValue = "";
  if (localStorage.accountData) {
    const localObjectData = decrypt(localStorage.accountData);
    if (key) {
      returnValue = localObjectData[key] ? localObjectData[key] : "";
    } else {
      returnValue = localObjectData;
    }
  }
  return returnValue;
}

export function removeFromLocalStorage(key = "") {
  if (localStorage.accountData) {
    const localObjectData = decrypt(localStorage.accountData);
    delete localObjectData[key];
    localStorage.accountData = encrypt(localObjectData);
  }
}

// Return header and append token in header if exist in local storage
export function getHeaderData() {
  let header = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };
  if (getDataFromLocalStorage("access_token")) {
    header = {
      ...header,
      ...{ Authorization: `Bearer ${getDataFromLocalStorage("access_token")}` },
    };
  }
  return header;
}

// Trim all left space from string
export const trimLeftSpace = (value) => value.replace(/^\s+/g, "");

// Return array of year from start year to end year
export const getYearByCount = (startYear, endYear) => {
  let returnValue = [];
  while (startYear <= endYear) {
    returnValue.push(`${startYear++}`);
  }
  return returnValue;
};

// convert string into title case
export const titleCaseString = (value) => {
  if (typeof value !== "string") return "";
  return value.toLowerCase().replace(/\b(\w)/g, (s) => s.toUpperCase()); // Capital first character of each word
};

// Copy text
export const copyToClipboard = async (text) => {
  const textarea = document.createElement("textarea");
  textarea.value = text;

  // Make it hidden
  textarea.style.position = "fixed";
  textarea.style.opacity = 0;

  // Append it to the document
  document.body.appendChild(textarea);

  // Select the text and copy it to the clipboard
  textarea.select();
  document.execCommand("copy");

  // Remove the temporary textarea
  document.body.removeChild(textarea);
};

export const generateOrderId = () => {
  return `UN-${Math.floor(100000 + Math.random() * 900000)}`;
};
