import { useSelector } from "react-redux";
import React from "react";
import "./SmartContracts.scss";
import { icons } from "utils/constants";

const SmartContracts = () => {
  const { isDarkMode } = useSelector((state) => state.global) || {};
  const textColorClass = isDarkMode ? "text-white" : "text-black";
  const scrollClass = isDarkMode ? "custom-scroll-dark" : "custom-scroll";

  const ContractSection = ({ title, contracts }) => (
    <div className="col-12 mb-5">
      {title && (
        <h5>
          <a href="#ooo-contract-addresses" className="h-anchor">
            #
          </a>
          {title}
        </h5>
      )}
      <div className="mt-3">
        {contracts.map((contract, index) => (
          <div key={index} className="mb-2 d-flex align-items-center gap-3">
            <strong>{contract.label}: </strong>
            <div
              className="fa-center flex-nowrap gap-1 pointer"
              onClick={() => window.open(contract.link, "_blank")}
            >
              <a
                href={contract.link}
                target="_blank"
                rel="noopener noreferrer "
                className={`${isDarkMode ? "link-dBg" : "link-bg"}`}
              >
                <div>{contract.address}</div>
              </a>
              <div className="h-16 mb-2 opacity-75">
                <img
                  src={icons.bLink}
                  alt=""
                  className="fit-image"
                  loading="lazy"
                  style={{
                    filter: isDarkMode ? "invert(1)" : "invert(0)",
                  }}
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <div id="smartcontracts-container" className={`cp-38 ${scrollClass}`}>
      <div className={`contract-addresses container ${textColorClass}`}>
        <h1 className="mb-3">
          <a href="#ooo-contract-addresses" className="header-anchor">
            #
          </a>
          OoO Contract Addresses
        </h1>
        <p>
          Addresses for the currently deployed contracts, required for
          interaction and integration.
        </p>

        {/* Mainnets Section */}
        <div className="col-12 mt-5 mb-3">
          <h3 className="border-bottom border-1 pb-2">
            <a href="#ooo-contract-addresses" className="h-anchor">
              #
            </a>
            Mainnets
          </h3>
        </div>
        <ContractSection
          title="ETH Mainnet"
          contracts={[
            {
              label: "xFUND Token",
              address: "0x892a06f9d0f147e5f879b0993f486f90ac3c87881",
              link: "https://etherscan.io/address/0x892a06f9d0f147e5f879b0993f486f90ac3c87881",
            },
            {
              label: "Router",
              address: "0x9ac9aE20a17779c71b069b4848788e3455fc6121",
              link: "https://etherscan.io/address/0x9ac9aE20a17779c71b069b4848788e3455fc6121",
            },
          ]}
        />
        <ContractSection
          title="Shibarium Mainnet"
          contracts={[
            {
              label: "xFUND Token",
              address: "0x89dc93c6e12c4e47acf4a9036f74423c08d8b2",
              link: "https://www.shibariumscan.io/token/0x89dc93c6e12c4e47acf4a9036f74423c08d8b2",
            },
            {
              label: "Router",
              address: "0x2e9a0de49900e19735689806e661bf633aa6588b1",
              link: "https://www.shibariumscan.io/token/0x2e9a0de49900e19735689806e661bf633aa6588b1",
            },
          ]}
        />
        <ContractSection
          title="Polygon Mainnet"
          contracts={[
            {
              label: "xFUND Token",
              address: "0x773a384f78e4685afa9c416b36e6aea6122567b",
              link: "https://polygonscan.com/address/0x773a384f78e4685afa9c416b36e6aea6122567b",
            },
            {
              label: "Router",
              address: "0x5e9405888255c142207ab06927a0c8dc6f8c53ca2",
              link: "https://polygonscan.com/address/0x5e9405888255c142207ab06927a0c8dc6f8c53ca2",
            },
          ]}
        />

        {/* Testnets Section */}
        <div className="col-12 mt-5">
          <h3 className="border-bottom border-1 pb-2">
            <a href="#ooo-contract-addresses" className="h-anchor">
              #
            </a>
            Testnets
          </h3>
        </div>
        <ContractSection
          title="ETH TestNet (Sepolia)"
          contracts={[
            {
              label: "xFUND Test Token",
              address: "0x0b07c72acf37dA95d28c566af98362f8cc8196",
              link: "https://sepolia.etherscan.io/address/0x0b07c72acf37dA95d28c566af98362f8cc8196#code",
            },
            {
              label: "Router",
              address: "0xf6b5d6eaef402d22069e085d3394cb359cd3a31",
              link: "https://sepolia.etherscan.io/address/0xf6b5d6eaef402d22069e085d3394cb359cd3a31#code",
            },
          ]}
        />
        <div className="col-12 mb-3 mt-5">
          <h5>
            <a href="#ooo-contract-addresses" className="h-anchor">
              #
            </a>
            Shibarium Puppynet
          </h5>
          <div className={`${isDarkMode ? "noteBlockD" : "noteBlock"}`}>
            <strong>Note:</strong>
            {"  "}xFUND on Puppynet is bridged from Sepolia. Minting is required
            on the Sepolia contract, and the Test xFUND can then be bridged to
            Puppynet.
          </div>
          <ContractSection
            contracts={[
              {
                label: "xFUND Test Token",
                address: "0x7f02e2232eaE465D87398E3a14b0c1fdd0cafD5",
                link: "https://puppyscan.shib.io/address/0x7f02e2232eaE465D87398E3a14b0c1fdd0cafD5",
              },
              {
                label: "Router",
                address: "0x790f98F8fC71C3131a38Af8A84be34Ae20100a621",
                link: "https://puppyscan.shib.io/address/0x790f98F8fC71C3131a38Af8A84be34Ae20100a621",
              },
            ]}
          />
          <p className="mb-0 mt-0">
            See Providers for OoO Data Provider addresses
          </p>
        </div>
      </div>
    </div>
  );
};

export default SmartContracts;
