import { useSelector } from "react-redux";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "./Label.scss";
import { icons } from "utils/constants";

const Label = ({ label, required, info }) => {
  const reduxData = useSelector((state) => state.global);
  const { isDarkMode } = reduxData || {};

  return (
    <div id="label-container" className="fa-center gap-2">
      <label
        className={`text-16-400 ${isDarkMode ? "color-ffff" : "color-2749"}`}
        htmlFor={`${label}`}
      >
        {label}
        {required && "*"}
      </label>
      {info && (
        <div>
          <span
            className="h-14 d-flex pointer"
            data-tooltip-id={`info-tip-${label}`}
          >
            <img
              src={icons.activeInfo}
              alt="activeInfo"
              className="fit-image"
            />
          </span>
          <ReactTooltip
            id={`info-tip-${label}`}
            place="center end"
            content={info}
            className={`label-info-tip ${isDarkMode ? "dark " : ""}`}
          />
        </div>
      )}
    </div>
  );
};

export default Label;
