import { Label } from "components";
import Select from "react-select";
import "./Dropdown.scss";

const Dropdown = ({
  label,
  required,
  optionKey,
  optionLabel,
  value,
  options,
  onChange,
  id,
  name,
  placeholder,
  disabled,
  isLoading,
  isClearable,
  isSearchable,
  onMenuScrollToBottom,
  onInputChange,
  error,
  isDarkMode,
  info,
  defaultValue,
  className,
}) => {
  const CustomSingleValue = ({ data }) => (
    <div className="d-flex align-items-center gap-2">
      {data.icon && (
        <div className="w-20 h-20">
          <img src={data.icon} alt="" className="fit-image" />
        </div>
      )}
      <span>{data.label}</span>
    </div>
  );

  const CustomOption = (props) => {
    const { data, innerRef, innerProps } = props;
    return (
      <div
        ref={innerRef}
        {...innerProps}
        className="d-flex align-items-center gap-2 option"
      >
        {data.icon && (
          <div className="w-20 h-20">
            <img src={data.icon} alt="" className="fit-image" />
          </div>
        )}
        <span>{data.label}</span>
      </div>
    );
  };

  const optId = optionKey || "id";
  const optVal = optionLabel || "label";
  let fillValue =
    options?.find((o) => `${o?.[optId]}` === `${value}`) || defaultValue;
  if (!fillValue) {
    fillValue = null;
  }
  return (
    <div id={`${isDarkMode ? "dark" : "dropdown-container"}`}>
      {label && <Label label={label} required={required} info={info} />}
      <div className="select-input-container">
        <Select
          components={{
            SingleValue: CustomSingleValue,
            Option: CustomOption,
          }}
          getOptionValue={(option) => option[optId]}
          placeholder={placeholder}
          className={`basic-single ${
            value ? "" : "placehoder-val"
          } ${className}`}
          classNamePrefix="select"
          value={fillValue}
          isDisabled={disabled}
          isLoading={isLoading}
          name={name}
          options={options}
          isClearable={isClearable}
          isSearchable={isSearchable}
          onMenuScrollToBottom={onMenuScrollToBottom}
          getOptionLabel={(option) => {
            return option[optVal];
          }}
          onInputChange={(text, event) => {
            if (onInputChange && event?.action === "input-change") {
              onInputChange(text);
            }
          }}
          onChange={(e) => {
            onChange({
              target: {
                id: id,
                value: e ? e[optId] : "",
                data: e,
              },
            });
          }}
          defaultValue={defaultValue}
          // menuIsOpen
        />
        {error && <div className="input-error">{error}</div>}
      </div>
    </div>
  );
};

export default Dropdown;
