import React from "react";
import DarkTheme from "pages/DarkTheme";
import { icons } from "utils/constants";
import { useSelector } from "react-redux";
import "./NavBar.scss";

const NavBar = () => {
  const { isDarkMode } = useSelector((state) => state.global) || {};
  return (
    <div className={`cpt-22 cpb-22 ${isDarkMode ? "bb-4d6c" : "bb-e3e3"}`}>
      <div className="fb-center container">
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ marginRight: "10px" }}>
            <img
              src={icons.unificationIcon}
              alt="logo"
              style={{ maxWidth: "50px", height: "auto" }}
            />
          </div>
          <h2
            style={{
              background: "linear-gradient(90deg, #ff6edd, #6b78ff)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            UNIFICATION
          </h2>
        </div>
        <div className="fb-center gap-2">
          <DarkTheme />
        </div>
      </div>
    </div>
  );
};

export default NavBar;
