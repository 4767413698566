import { useSelector } from "react-redux";
import "./OoOInitialisation.scss";
import SyntaxHighlighter from "react-syntax-highlighter";
import { atomOneDark } from "react-syntax-highlighter/dist/esm/styles/hljs";

const OoOInitialisation = () => {
  const { isDarkMode } = useSelector((state) => state.global) || {};
  const textColorClass = isDarkMode ? "text-white" : "text-black";
  const scrollClass = isDarkMode ? "custom-scroll-dark" : "custom-scroll";
  const syntaxStyle = atomOneDark;

  return (
    <div id="oooinitialisation-container" className={`cp-38 ${scrollClass}`}>
      <div className={`contract-addresses container ${textColorClass}`}>
        <h1 id="ooo-quickstart" className="mb-3">
          <a href="#ooo-quickstart" className="header-anchor">
            #
          </a>
          OoO Contract Initialisation and Data Request Guide
        </h1>
        <p>
          This guide will walk you through the steps required to initialise your
          contract (add Tokens and increase the Router's allowance), and also
          make a data request.
        </p>
        <div
          className={`${
            isDarkMode ? "yellowBlockD" : "yellowBlock"
          } custom-block tip`}
        >
          <strong className="custom-block-title">IMPORTANT</strong>
          <p>
            You do not need to implement or deploy the <code>Router.sol</code>{" "}
            smart contract.
          </p>
          <div>
            This is a smart contract deployed and maintained by the Unification
            Foundation and is the core of the xFUND Router network. Your smart
            contract will only import and build on the{" "}
            <code>ConsumerBase.sol</code> base smart contract, which in turn
            interacts with the <code>Router</code> smart contract.
          </div>
        </div>
        <p>
          Run the truffle development console, and connect to the Rinkeby
          testnet:
        </p>
        <SyntaxHighlighter language="powershell" style={syntaxStyle}>
          {`npx truffle console --network=sepolia`}
        </SyntaxHighlighter>
        <div className="note mt-3">
          <strong>Note</strong>
          <div>
            See OoO Data API Guide for the latest <strong>Rinkeby</strong>
            OoO Finchains data provider address, used for the{" "}
            <code>provider</code> variable below
          </div>
        </div>
        <p>
          Within the <code>truffle</code> console, load the contract instances,
          and accounts ready for interaction
        </p>
        <SyntaxHighlighter language="powershell" style={syntaxStyle}>
          {`truffle(sepolia)> let accounts = await web3.eth.getAccounts()
  truffle(sepolia)> let consumerOwner = accounts[0]
  truffle(sepolia)> let provider = "0x611661f4B5D82079E924AcE2A6D113fAbd214b14"
  truffle(sepolia)> let myDataConsumer = await MyDataConsumer.deployed()`}
        </SyntaxHighlighter>
        <div className="mt-5 mb-3">
          <h3 className="border-bottom border-1 pb-2">
            <a href="#ooo-contract-addresses" className="h-anchor">
              #
            </a>
            1. Contract Initialisation
          </h3>
          <p>
            The following steps need only be done periodically, to ensure all
            parties have the correct amount of tokens and gas to pay for data.
          </p>
          <p>
            Go to{" "}
            <a
              href="https://etherscan.io"
              target="_blank"
              rel="noopener noreferrer"
            >
              xFUNDMOCK
            </a>{" "}
            on Etherscan, and connect MetaMask with the account used to deploy
            the <code>MyDataConsumer</code> smart contract. Then, run the{" "}
            <code>gimme()</code> function. This is a faucet function, and will
            supply your wallet with 10 xFUNDMOCK tokens. You may do this once
            per hour.
          </p>
          <p>
            Get the deployed address for your <code>MyDataConsumer</code> smart
            contract:
          </p>
          <SyntaxHighlighter language="javascript" style={syntaxStyle}>
            {`truffle(sepolia)> myDataConsumer.address`}
          </SyntaxHighlighter>
          <p>
            Next, using either Etherscan or MetaMask, transfer 5 xFUNDMOCK
            tokens to your <code>MyDataConsumer</code> contract address.
          </p>
          <p>
            Finally, we need to allow the Router smart contract to pay fees on
            the <code>MyDataConsumer</code> contract's behalf:
          </p>
          <SyntaxHighlighter language="javascript" style={syntaxStyle}>
            {`truffle(sepolia)> myDataConsumer.increaseRouterAllowance("115792089237316195423570985008687907853269984665640564039457584007913129639935", {from: consumerOwner})`}
          </SyntaxHighlighter>
        </div>
        <div className="mt-5 mb-3">
          <h3 className="border-bottom border-1 pb-2">
            <a href="#ooo-contract-addresses" className="h-anchor">
              #
            </a>
            2. Data Request
          </h3>
          <p>
            Now that the <code>MyDataConsumer</code> smart contract has been
            initialised, we can request data to be sent to the smart contract.
            You may need to top up the Consumer contract's tokens every so
            often.
          </p>
          <p>
            First, check the current price in your <code>MyDataConsumer</code>{" "}
            contract. Run:
          </p>
          <SyntaxHighlighter language="javascript" style={syntaxStyle}>
            {`truffle(sepolia)> let priceBefore = await myDataConsumer.price()
truffle(sepolia)> priceBefore.toString()`}
          </SyntaxHighlighter>
          <p>
            The result should be <code>0</code>.
          </p>
          <p>Next, request some data from the provider. Run:</p>
          <SyntaxHighlighter language="javascript" style={syntaxStyle}>
            {`truffle(sepolia)> let endpoint = web3.utils.asciiToHex("BTC.USD.PR.AVI")
truffle(sepolia)> myDataConsumer.getData(provider, 100000000, endpoint, {from: consumerOwner})`}
          </SyntaxHighlighter>
          <p>
            The first command encodes the data endpoint (the data we want to
            get) into a <code>bytes32</code> value. We are requesting the mean
            (PR.AVI) US dollar (USD) price of Bitcoin (BTC), with outliers (very
            high or very low) values removed (AVI as opposed to AVG) from the
            final mean calculation.
          </p>
          <p>
            A full list of supported currency pairs is available from the{" "}
            <a
              href="https://finchains.com/api"
              target="_blank"
              rel="noopener noreferrer"
            >
              Finchains API
            </a>
            .
          </p>
          <p>
            It may take a block or two for the request to be fully processed—the
            provider will listen for the request, then submit a transaction with
            the data to the Router, which will forward it to your smart
            contract.
          </p>
          <p>After 30 seconds or so, run:</p>
          <SyntaxHighlighter language="javascript" style={syntaxStyle}>
            {`truffle(sepolia)> let priceAfter = await myDataConsumer.price()
truffle(sepolia)> priceAfter.toString()`}
          </SyntaxHighlighter>
          <p>
            If the price is still 0, simply run the following a couple more
            times:
          </p>
          <SyntaxHighlighter language="javascript" style={syntaxStyle}>
            {`truffle(sepolia)> priceAfter = await myDataConsumer.price()
truffle(sepolia)> priceAfter.toString()`}
          </SyntaxHighlighter>
          <p>The price should now be a non-zero value.</p>
          <div className="note mt-3">
            <strong>Note</strong>
            <p>
              By default, the OoO sends all price data converted to{" "}
              <code>actualPrice * (10 ** 18)</code> in order to remove any
              decimals.
            </p>
            <p>To convert to the actual decimal price, you can for example:</p>
            <SyntaxHighlighter language="javascript" style={syntaxStyle}>
              {`truffle(sepolia)> let actualPrice = web3.utils.fromWei(priceAfter)
truffle(sepolia)> actualPrice.toString()`}
            </SyntaxHighlighter>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OoOInitialisation;
