import { icons } from "utils/constants";
import { Dropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
// import { ConnectButton } from "@rainbow-me/rainbowkit";
import { GlobalSearch } from "components";
import "./Navbar.scss";
import DarkTheme from "pages/DarkTheme";

const Navbar = ({ handleLogout }) => {
  const navigate = useNavigate();
  const reduxData = useSelector((state) => state.global);
  const { profileImage, isSubscription, isDarkMode } = reduxData || {};

  return (
    <div id={`${isDarkMode ? "navbar-container-dark" : "navbar-container"}`}>
      <GlobalSearch isSubscription={isSubscription} />

      <div className="fa-center gap-3">
        <DarkTheme />
        {/* <ConnectButton /> */}

        <Dropdown>
          <Dropdown.Toggle variant="success" id="dropdown-basic">
            <div className="profile-block fa-center gap-2">
              <span className="h-35">
                <img
                  src={profileImage || icons.user}
                  alt="wallet"
                  className="fit-image rounded-circle"
                />
              </span>
              <span className="h-20">
                <img
                  src={isDarkMode ? icons.downArrowLight : icons.downArrow}
                  alt="wallet"
                  className="fit-image"
                />
              </span>
            </div>
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item
              className={`bb-dee3 pb-2 text-14-400 ${
                isDarkMode ? "color-ffff" : "color-2749"
              }`}
              onClick={(e) => {
                e.preventDefault();
                navigate("/profile");
              }}
            >
              Profile
            </Dropdown.Item>
            <Dropdown.Item
              className={`pt-2 text-14-400  ${
                isDarkMode ? "color-ffff" : "color-2749"
              }`}
              onClick={(e) => {
                e.preventDefault();
                handleLogout();
              }}
            >
              Logout
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
};

export default Navbar;
