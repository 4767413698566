import React, { useEffect, useRef } from "react";
import { Promptalert } from "components";
import { useSelector } from "react-redux";
import { RouterProvider } from "react-router-dom";
import router from "routes";
import "./App.css";

const App = () => {
  const reduxData = useSelector((state) => state.global);
  const { isDarkMode } = reduxData || {};
  const resizeObserverRef = useRef(null);

  // Prevent ResizeObserver loop error
  const debounce = (func, delay) => {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  };

  const handleResize = debounce((entries) => {
    entries.forEach((entry) => {});
  }, 100);
  useEffect(() => {
    const observedElement = document.querySelector("#root");
    if (!observedElement) return;
    resizeObserverRef.current = new ResizeObserver(handleResize);
    resizeObserverRef.current.observe(observedElement);
    return () => {
      resizeObserverRef.current.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Toggle theme
  let theme = localStorage.getItem("theme");
  const body = document.querySelector("body");
  if (theme === "dark") {
    body.classList.add("dark-theme");
    body.classList.remove("light-theme");
  } else {
    body.classList.remove("dark-theme");
    body.classList.add("light-theme");
  }

  useEffect(() => {
    const root = document.documentElement;
    root.style.setProperty("--is-body-color", isDarkMode ? "#02041E" : "#fff");
    root.style.setProperty("--is-text-color", isDarkMode ? "#fff" : "#000");
    root.style.setProperty(
      "--is-border-color",
      isDarkMode ? "#ffffff80" : "#0000002b"
    );
    root.style.setProperty(
      "--is-invert",
      isDarkMode ? "invert(1)" : "invert(0)"
    );
    root.style.setProperty(
      "--is-box-color",
      isDarkMode ? "rgba(255, 255, 255, 0.15)" : "rgba(0, 0, 0, 0.15)"
    );
    root.style.setProperty(
      "--is-hover-bg-color",
      isDarkMode ? "#3f415b" : "#f2f3f2"
    );
  }, [isDarkMode]);

  useEffect(() => {
    const preloader = document.getElementById("preloader");
    if (preloader) {
      preloader.style.display = "none";
    }
  }, []);

  useEffect(() => {
    function hideError(e) {
      if (
        e.message ===
        "ResizeObserver loop completed with undelivered notifications."
      ) {
        const resizeObserverErrDiv = document.getElementById(
          "webpack-dev-server-client-overlay-div"
        );
        const resizeObserverErr = document.getElementById(
          "webpack-dev-server-client-overlay"
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute("style", "display: none");
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute("style", "display: none");
        }
      }
    }
    window.addEventListener("error", hideError);
    return () => {
      window.addEventListener("error", hideError);
    };
  }, []);

  return (
    <div className={`App vh-100 ${isDarkMode ? "dark-mode" : ""}`}>
      <Promptalert />
      <RouterProvider router={router} />
    </div>
  );
};

export default App;
