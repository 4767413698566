import unification from "assets/images/icons/unification.png";
import logout from "assets/images/icons/logout.png";
import logoutDark from "assets/images/icons/logoutDark.png";
import squareClose from "assets/images/icons/squareClose.png";
import close from "assets/images/icons/close.png";
import closeDark from "assets/images/icons/closeDark.png";
import downArrow from "assets/images/icons/downArrow.png";
import upArrow from "assets/images/icons/upArrow.png";

import downArrowLight from "assets/images/icons/downArrowLight.png";
import user from "assets/images/icons/user.png";
import logo from "assets/images/icons/logo.png";
import banner from "assets/images/icons/banner.png";
import bannerDark from "assets/images/icons/bannerDark.png";
import wallet from "assets/images/icons/wallet.png";
import walletDark from "assets/images/icons/walletDark.png";
import metamask from "assets/images/icons/metamask.png";
import Metamaskicon from "assets/images/icons/Metamaskicon.png";
import dashboard from "assets/images/icons/dashboard.png";
import myProject from "assets/images/icons/myProject.png";
import project from "assets/images/icons/project.png";
import admin from "assets/images/icons/admin.png";
import subscription from "assets/images/icons/subscription.png";
import subscriptions from "assets/images/icons/subscriptions.png";
import subscriptionsLight from "assets/images/icons/subscriptionsLight.png";
import cancelSubscription from "assets/images/icons/cancelSubscription.png";
import cancelSubscriptionLight from "assets/images/icons/cancelSubscriptionLight.png";
import usage from "assets/images/icons/usage.png";
import activeDashboard from "assets/images/icons/activeDashboard.png";
import activeMyProject from "assets/images/icons/activeMyProject.png";
import activeProject from "assets/images/icons/activeProject.png";
import activeAdmin from "assets/images/icons/activeAdmin.png";
import activeSubscription from "assets/images/icons/activeSubscription.png";
import activeUsage from "assets/images/icons/activeUsage.png";
import search from "assets/images/icons/search.png";
import searchLight from "assets/images/icons/searchLight.png";
import emptyProject from "assets/images/icons/emptyProject.png";
import arrowRight from "assets/images/icons/arrowRight.png";
import key from "assets/images/icons/key.png";
import keyLight from "assets/images/icons/keyLight.png";
import addSquare from "assets/images/icons/addSquare.png";
import filterUpArrow from "assets/images/icons/filterUpArrow.png";
import filterUpArrowLight from "assets/images/icons/filterUpArrowLight.png";
import filterDownArrow from "assets/images/icons/filterDownArrow.png";
import filterDownArrowLight from "assets/images/icons/filterDownArrowLight.png";
import darkArrowLeft from "assets/images/icons/darkArrowLeft.png";
import darkArrowRight from "assets/images/icons/darkArrowRight.png";
import lightArrowRight from "assets/images/icons/lightArrowRight.png";
import lightArrowLeft from "assets/images/icons/lightArrowLeft.png";
import pagination from "assets/images/icons/pagination.png";
import paginationLight from "assets/images/icons/paginationLight.png";
import more from "assets/images/icons/more.png";
import moreLight from "assets/images/icons/moreLight.png";
import activeMore from "assets/images/icons/activeMore.png";
import activeMoreLight from "assets/images/icons/activeMoreLight.png";
import trash from "assets/images/icons/trash.png";
import edit from "assets/images/icons/edit.png";
import copy from "assets/images/icons/copy.png";
import deleteFrame from "assets/images/icons/deleteFrame.png";
import deleteFrameLight from "assets/images/icons/deleteFrameLight.png";
import editLight from "assets/images/icons/editLight.png";
import infoCircle from "assets/images/icons/infoCircle.png";
import infoCircleLight from "assets/images/icons/infoCircleLight.svg";
import profileSelection from "assets/images/icons/profileSelection.png";
import profileSelectionLight from "assets/images/icons/profileSelectionLight.png";
import check from "assets/images/icons/check.png";
import checkLight from "assets/images/icons/checkLight.png";
import helpCircle from "assets/images/icons/helpCircle.png";
import helpCircleLight from "assets/images/icons/helpCircleLight.png";
import superfluid from "assets/images/icons/superfluid.png";
import blueLogo from "assets/images/icons/blueLogo.png";
import activeMessage from "assets/images/icons/activeMessage.png";
import activeFolder from "assets/images/icons/activeFolder.png";
import crown from "assets/images/icons/crown.png";
import unodeChain from "assets/images/icons/unodeChain.png";
import shibariumChain from "assets/images/icons/shibariumChain.png";
import ethereumChain from "assets/images/icons/ethereumChain.png";
import purSub from "assets/images/icons/purSub.png";
import purSubLight from "assets/images/icons/purSubLight.png";
import eye from "assets/images/icons/eye.png";
import eyelight from "assets/images/icons/eyelight.png";
import eyeSlash from "assets/images/icons/eyeSlash.png";
import eyeslashlight from "assets/images/icons/eyeslashlight.png";
import arrowCircleDown from "assets/images/icons/arrowCircleDown.png";
import arrowCircleUp from "assets/images/icons/arrowCircleUp.png";
import transaction from "assets/images/icons/transaction.png";
import activeTransaction from "assets/images/icons/activeTransaction.png";
import infinity from "assets/images/icons/infinity.png";
import information from "assets/images/icons/information.png";
import Upload from "assets/images/icons/Vector.png";
import UploadLight from "assets/images/icons/VectorLight.png";
import feedback from "assets/images/icons/feedback.png";
import add from "assets/images/icons/add.png";
import nowPayment from "assets/images/icons/nowPayment.png";
import YesIcon from "assets/images/icons/YesIcon.png";
import candle from "assets/images/icons/candle.png";
import candleLight from "assets/images/icons/candleLight.png";
import crypto from "assets/images/icons/crypto.jpg";
import logoDark from "assets/images/icons/logoDark.png";
import activedashBoardDark from "assets/images/icons/activedashBoardDark.png";
import activeTransactiondark from "assets/images/icons/activeTransactiondark.png";
import arrowRightLight from "assets/images/icons/arrowRightLight.png";
import emptyProjectLight from "assets/images/icons/emptyProjectLight.png";
import activeAdminLight from "assets/images/icons/activeAdminLight.png";
import activeMyProjectLight from "assets/images/icons/activeMyProjectLight.png";
import activeProjectLight from "assets/images/icons/activeProjectLight.png";
import activeSubscriptionLight from "assets/images/icons/activeSubscriptionLight.png";
import activeUsageLight from "assets/images/icons/activeUsageLight.png";
import payment from "assets/images/icons/payment.png";
import paymentDark from "assets/images/icons/paymentDark.png";
import paymentDark1 from "assets/images/icons/paymentDark1.png";
import calendar from "assets/images/icons/calendar.png";
import downloadLog from "assets/images/icons/downloadLog.png";
import node from "assets/images/icons/node.png";
import timer from "assets/images/icons/timer.png";
import nodeEdit from "assets/images/icons/nodeEdit.png";
import nodeDelete from "assets/images/icons/nodeDelete.png";
import activeInfo from "assets/images/icons/activeInfo.png";
import refresh from "assets/images/icons/refresh.png";
import download from "assets/images/icons/download.png";
import info from "assets/images/icons/info.png";
import defaultChain from "assets/images/icons/defaultChain.png";
import redDown from "assets/images/icons/redDown.png";
import coin from "assets/images/icons/coin.png";
import coindark from "assets/images/icons/coindark.png";
import coinLight from "assets/images/icons/coinLight.png";
import payout from "assets/images/icons/payout.png";
import walletDark1 from "assets/images/icons/walletDark1.png";
import walletLight from "assets/images/icons/walletLight.png";
import recordCircle from "assets/images/icons/recordCircle.png";
import pause from "assets/images/icons/pause.png";
import GoogleIcon from "assets/images/icons/google.png";
import unificationIcon from "assets/images/icons/unification-icon.png";
import github from "assets/images/icons/github.png";
import bLink from "assets/images/icons/bLink.png";

export const icons = {
  bLink,
  unification,
  unificationIcon,
  closeDark,
  pause,
  payout,
  walletDark1,
  walletLight,
  coindark,
  coinLight,
  coin,
  redDown,
  defaultChain,
  info,
  refresh,
  download,
  paymentDark,
  paymentDark1,
  payment,
  activeMoreLight,
  moreLight,
  paginationLight,
  cancelSubscriptionLight,
  subscriptionsLight,
  helpCircleLight,
  checkLight,
  filterDownArrowLight,
  filterUpArrowLight,
  profileSelectionLight,
  infoCircleLight,
  deleteFrameLight,
  purSubLight,
  searchLight,
  UploadLight,
  candleLight,
  activeUsageLight,
  activeSubscriptionLight,
  activeMyProjectLight,
  activeAdminLight,
  emptyProjectLight,
  arrowRightLight,
  eyeslashlight,
  eyelight,
  logoDark,
  activedashBoardDark,
  activeTransactiondark,
  candle,
  crypto,
  Metamaskicon,
  cancelSubscription,
  subscriptions,
  Upload,
  add,
  logout,
  logoutDark,
  squareClose,
  close,
  downArrow,
  upArrow,
  downArrowLight,
  user,
  logo,
  banner,
  bannerDark,
  wallet,
  walletDark,
  metamask,
  dashboard,
  myProject,
  admin,
  activeDashboard,
  activeMyProject,
  activeAdmin,
  search,
  emptyProject,
  arrowRight,
  key,
  keyLight,
  addSquare,
  filterUpArrow,
  filterDownArrow,
  darkArrowLeft,
  lightArrowRight,
  pagination,
  more,
  activeMore,
  trash,
  edit,
  copy,
  deleteFrame,
  editLight,
  infoCircle,
  profileSelection,
  lightArrowLeft,
  darkArrowRight,
  check,
  helpCircle,
  superfluid,
  activeSubscription,
  subscription,
  blueLogo,
  activeMessage,
  activeFolder,
  usage,
  activeUsage,
  crown,
  unodeChain,
  shibariumChain,
  ethereumChain,
  purSub,
  eye,
  eyeSlash,
  arrowCircleDown,
  arrowCircleUp,
  transaction,
  activeTransaction,
  infinity,
  information,
  feedback,
  nowPayment,
  YesIcon,
  calendar,
  downloadLog,
  project,
  activeProject,
  activeProjectLight,
  node,
  timer,
  nodeEdit,
  nodeDelete,
  activeInfo,
  recordCircle,
  GoogleIcon,
  github,
};
