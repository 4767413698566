import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { icons } from "utils/constants";
import { Card, Container } from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

import "./Login.scss";
import { Button } from "components";
import { handleLoginApi } from "store/globalSlice";

// Validation schema using Yup
const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email format")
    .required("Email is required"),
  password: Yup.string()
    .min(6, "Password must be at least 6 characters")
    .required("Password is required"),
});

const Login = () => {
  const dispatch = useDispatch();
  // Accessing global Redux state
  const { isDarkMode } = useSelector((state) => state.global) || {};
  const navigate = useNavigate();

  const handleLogin = async (values) => {
    try {
      const res = await dispatch(handleLoginApi(values));
      if (res?.status === 200) {
        navigate("/main-dashboard");
      } else {
        //
      }
    } catch (err) {
      //
      console.error("Signup error: ", err);
    } finally {
      //
    }
  };

  return (
    <>
      {/* Login Form Section */}
      <div
        className={`login-page ${isDarkMode ? "dark-mode" : "light-mode"}`}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "80vh",
          padding: "20px",
        }}
      >
        <Container className="d-flex justify-content-center align-items-center">
          <Card
            className="p-3 shadow-lg rounded"
            style={{
              maxWidth: "450px",
              width: "100%",
              backgroundColor: isDarkMode ? "#1E1E2F" : "#f9f9f9",
              color: isDarkMode ? "#FFF" : "#000",
              border: "3px solid transparent !important",
              borderImage:
                "linear-gradient(90deg, rgb(255, 110, 221), rgb(107, 120, 255)) 1",
            }}
          >
            <Card.Body>
              <div className="text-center mb-4">
                <img
                  src={icons.unificationIcon}
                  alt="logo"
                  style={{ maxWidth: "80px", height: "auto" }}
                />
              </div>

              <div className="mt-3">
                <h5
                  className="text-center mb-3"
                  style={{
                    color: isDarkMode ? "#DDD" : "#555",
                  }}
                >
                  Sign In to Continue
                </h5>
                <Formik
                  initialValues={{ email: "", password: "" }}
                  validationSchema={validationSchema}
                  onSubmit={handleLogin}
                >
                  {({ isSubmitting }) => (
                    <Form>
                      <div className="mb-3">
                        <label
                          htmlFor="email"
                          style={{ color: isDarkMode ? "#FFF" : "#000" }}
                        >
                          Email
                        </label>
                        <Field
                          id="email"
                          name="email"
                          type="email"
                          placeholder="Enter your email"
                          className="form-control py-1"
                          style={{
                            fontSize: "1rem",
                            // color: isDarkMode ? "#FFF" : "#000",
                            border: "1px solid",
                            borderColor: isDarkMode ? "#4D4D7E" : "#CCC",
                          }}
                        />
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="text-danger mt-1"
                        />
                      </div>

                      <div className="mb-4">
                        <label
                          htmlFor="password"
                          style={{ color: isDarkMode ? "#FFF" : "#000" }}
                        >
                          Password
                        </label>
                        <Field
                          id="password"
                          name="password"
                          type="password"
                          placeholder="Enter your password"
                          className="form-control py-1"
                          style={{
                            fontSize: "1rem",
                            // color: isDarkMode ? "#FFF" : "#000",
                            border: "1px solid",
                            borderColor: isDarkMode ? "#4D4D7E" : "#CCC",
                          }}
                        />
                        <ErrorMessage
                          name="password"
                          component="div"
                          className="text-danger mt-1"
                        />
                      </div>

                      <Button
                        btnStyle="PD"
                        className="w-100 py-2"
                        btnType="submit"
                        btnText={isSubmitting ? "Signing In..." : "Sign In"}
                        disabled={isSubmitting}
                        style={{
                          color: "#FFF",
                          border: "none",
                          fontSize: "1.2rem",
                          fontWeight: "bold",
                        }}
                      />
                    </Form>
                  )}
                </Formik>
              </div>

              <div className="text-center mt-3">
                <span>
                  Don't have an account?
                  <Link
                    to="/signup"
                    style={{
                      color: "#FF6EDD",
                      textDecoration: "none",
                      fontWeight: "bold",
                      paddingLeft: "5px",
                    }}
                  >
                    Sign Up For Free
                  </Link>
                </span>
              </div>

              {/* <hr
                style={{
                  borderColor: isDarkMode ? "#4D4D7E" : "#DDD",
                  marginTop: "20px",
                }}
              /> */}
              {/* <div className="text-center ">
                <h5
                  className="text-center mb-3"
                  style={{
                    color: isDarkMode ? "#DDD" : "#555",
                  }}
                >
                  Or Sign in with Wallet
                </h5>
                <Button
                  className="w-100"
                  btnStyle="PD"
                  leftIcon={icons.walletDark}
                  btnText="Connect Wallet"
                  style={{
                    color: "#FFF",
                    border: "none",
                  }}
                />
              </div> */}
            </Card.Body>
          </Card>
        </Container>
      </div>
    </>
  );
};

export default Login;
