import BootrsapModal from "react-bootstrap/Modal";
import { icons } from "utils/constants";
import "./Modal.scss";
import { useSelector } from "react-redux";

const Modal = ({
  children,
  onHide,
  width,
  title,
  size,
  fullscreen,
  largeClose,
  hideCloseButton,
  isCloseOutside,
}) => {
  const reduxData = useSelector((state) => state.global);
  const { isDarkMode } = reduxData || {};
  // let newWidth = window.innerWidth < 700 ? "100%" : width || "";
  return (
    <BootrsapModal
      className="iferp-scroll modal-block-custom"
      onHide={onHide}
      size={size || "lg"}
      fullscreen={fullscreen}
      centered
      backdrop={isCloseOutside ? "static" : "true"}
      show
    >
      <BootrsapModal.Body
        id={`modal-container`}
        className={`${isDarkMode ? "dark" : ""}`}
        style={{ width: width || "" }}
      >
        {!hideCloseButton && (
          <>
            {largeClose ? (
              <div className="modal-close-button pointer" onClick={onHide}>
                <img src={icons.squareClose} className="h-45" alt="close" />
              </div>
            ) : (
              <div className="modal-close-button pointer" onClick={onHide}>
                <img
                  src={isDarkMode ? icons.closeDark : icons.close}
                  className="h-20"
                  alt="close"
                />
              </div>
            )}
          </>
        )}
        {title && (
          <div
            className={`col-md-12 text-24-600 cpb-20 cps-26 cpe-26  ${
              isDarkMode ? "color-ffff bb-4d6c" : "color-2749 bb-edf0"
            }`}
          >
            {title}
          </div>
        )}

        {children}
      </BootrsapModal.Body>
    </BootrsapModal>
  );
};

export default Modal;
