import { useState, useRef, useEffect } from "react";
import { icons } from "utils/constants";
import "./Multipleselectdropdown.scss";
import Checkbox from "../Checkbox";
import { useSelector } from "react-redux";
import Label from "../Label";

const Multipleselectdropdown = ({
  value,
  options,
  onChange,
  title,
  label,
  isRequired,
  labelClass,
  staticOption,
}) => {
  const myRef = useRef();
  const reduxData = useSelector((state) => state.global);
  const { isDarkMode } = reduxData || {};
  const [show, setShow] = useState(false);

  const handleClickOutside = (e) => {
    if (myRef && myRef?.current && !myRef.current.contains(e.target)) {
      setShow(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  });

  const selectedData = value ? value?.split(",") : [];
  const allSelected = selectedData?.length === options?.length;
  const staticValue = options?.find((o) => o?.id === staticOption)?.label || "";
  return (
    <div
      id={`${
        isDarkMode
          ? "multipleselectdropdown-container-dark"
          : "multipleselectdropdown-container"
      }`}
    >
      {label && (
        <Label label={label} required={isRequired} className={labelClass} />
      )}
      <div className={`multip-select ${label ? "br-8" : ""}`}>
        <div
          className="fb-center w-100 pointer"
          onClick={() => {
            setShow(true);
          }}
        >
          <span className={selectedData.length === 0 ? "not-selected" : ""}>
            {staticValue ? (
              <Checkbox label={staticValue} checked onChange={() => {}} />
            ) : allSelected ? (
              `All ${title || "networks"}`
            ) : selectedData.length === 0 ? (
              `Select ${title || "networks"}`
            ) : (
              `${selectedData.length} ${title || "network"}${
                selectedData.length > 1 ? "s" : ""
              }`
            )}
          </span>
          <span className="d-flex h-16">
            <img
              src={isDarkMode ? icons.downArrowLight : icons.downArrow}
              alt="downArrow"
              className="fit-image"
            />
          </span>
        </div>
        {show && !staticOption && (
          <div className="option-popup shadow" ref={myRef}>
            {options?.map((elm, index) => {
              const isExist = selectedData?.includes(elm.id);
              return (
                <div
                  key={index}
                  className={isExist ? "actiove-op list-op" : "list-op"}
                  onClick={() => {
                    onChange(elm.id);
                  }}
                >
                  <Checkbox
                    label={elm.label}
                    checked={isExist}
                    onChange={() => {}}
                  />
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default Multipleselectdropdown;
