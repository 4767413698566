import "./Integration.scss";
import React from "react";
import { useSelector } from "react-redux";
import SyntaxHighlighter from "react-syntax-highlighter";
import { atomOneDark } from "react-syntax-highlighter/dist/esm/styles/hljs";

const Integration = () => {
  const { isDarkMode } = useSelector((state) => state.global) || {};
  const textColorClass = isDarkMode ? "text-white" : "text-black";
  const syntaxStyle = atomOneDark;

  return (
    <div
      id="integration-container"
      className={`cp-38 ${isDarkMode ? "custom-scroll-dark" : "custom-scroll"}`}
    >
      <div className={`contract-addresses container ${textColorClass}`}>
        <h1 id="ooo-quickstart" className="mb-3">
          <a href="#ooo-quickstart" className="header-anchor">
            #
          </a>
          VOR Full implementation example
        </h1>
        <p>
          In this guide, we'll run through a more detailed example and show you
          how to implement a simplified DnD D20 for fighting monsters and
          calculating hits.
        </p>
        <p>The resulting contract will:</p>
        <ul>
          <li>
            Allow any player to "roll for hit" against a selected monster, using
            a VOR request for the D20 roll
          </li>
          <li>Allow the owner to add up to 20 monsters</li>
          <li>Allow any player to register their STR modifier</li>
          <li>
            Allow the contract owner to implement and run some optional helper
            functions included in the <code>VORConsumerBase</code> contract.
          </li>
          <li>
            Players calling the <code>rollToHit</code> function will pay their
            own xFUNDMOCK fees.
          </li>
        </ul>
        <div
          className={`${
            isDarkMode ? "noteBlockD" : "noteBlock"
          } custom-block tip`}
        >
          <p>
            <strong className="custom-block-title">TIP</strong>
          </p>
          <div>
            <p>
              This guide assumes you have read through the{" "}
              <a
                href="https://docs.unification.io/vor/guide/quickstart.html"
                target="_blank"
                rel="noreferrer"
              >
                quickstart
              </a>{" "}
              and:
            </p>
            <ul>
              <li>Set up your development and NodeJS environment</li>
              <li>
                Initialised a new project (using npm, and Truffle/Hardhat, etc.)
              </li>
              <li>
                Installed the required <code>@unification-com/xfund-vor</code>{" "}
                and supplementary <code>@openzeppelin/contracts</code> packages
                using <code>npm</code>/<code>yarn</code>
              </li>
            </ul>
          </div>
        </div>
        <p>
          The full, final contract can be seen in our <code>vor-demos</code>{" "}
          GitHub repo:{" "}
          <a
            href="https://github.com/unification-com/vor-demos/blob/main/contracts/DnD.sol"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://github.com/unification-com/vor-demos/blob/main/contracts/DnD.sol
          </a>{" "}
          .
        </p>
        <div className="mt-5 mb-3">
          <h3 className="border-bottom border-1 pb-2">
            <a href="#ooo-contract-addresses" className="h-anchor">
              #
            </a>
            Initial contract skeleton
          </h3>
          <p>
            We'll start with a simple contract outline - some function stubs and
            the constructor:
          </p>
          <p>
            Create <code>contracts/DnD.sol.</code> All future edits will be in
            this file
          </p>
          <SyntaxHighlighter language="javascript" style={syntaxStyle}>
            {`// SPDX-License-Identifier: MIT
pragma solidity 0.6.12;
pragma experimental ABIEncoderV2;

import "@openzeppelin/contracts/math/SafeMath.sol";
import "@openzeppelin/contracts/access/Ownable.sol";
import "@unification-com/xfund-vor/contracts/VORConsumerBase.sol";

contract DnD is Ownable, VORConsumerBase {
    using SafeMath for uint256;

    constructor(address _vorCoordinator, address _xfund)
    public
    VORConsumerBase(_vorCoordinator, _xfund) {}

    function rollForHit(
        uint256 _monsterId, 
        uint256 _seed, 
        bytes32 _keyHash, 
        uint256 _fee
    ) 
    external returns (bytes32 requestId) {
        requestId = requestRandomness(_keyHash, _fee, _seed);
    }

    function fulfillRandomness(bytes32 _requestId, uint256 _randomness) 
    internal override {}
}`}
          </SyntaxHighlighter>
          <p>
            We're importing the <code>VORConsumerBase</code>, and some helper
            contracts from the awesome OpenZeppelin suite, and we have the three
            most basic functions required to implement VOR:
          </p>
          <ul>
            <li>
              A constructor which passes the addresses of the{" "}
              <code>VORCoordinator</code> and <code>xFUND</code> to the{" "}
              <code>VORConsumerBase</code>.
            </li>
            <li>
              A <code>rollForHit</code> function which will ultimately call the{" "}
              <code>requestRandomness</code> function in{" "}
              <code>VORConsumerBase</code>.
            </li>
            <li>
              A <code>fulfillRandomness</code> function which overrides the same
              function in <code>VORConsumerBase</code>, and which will be called
              by the VOR Oracle via the <code>VORCoordinator</code> smart
              contract.
            </li>
          </ul>
          <div
            className={`${
              isDarkMode ? "noteBlockD" : "noteBlock"
            } custom-block tip`}
          >
            <p>
              <strong className="custom-block-title">TIP</strong>
            </p>
            <div>
              <p>
                the <code>_keyHash</code> and <code>_fee</code> args passed to
                <code>rollForHit</code> function can be set/retrieved elsewhere
                in the contract, and passed to the{" "}
                <code>requestRandomness</code> function. Your request function
                does not necessarily require these arguments, but the values do
                ultimately need passing to
                <code>requestRandomness</code>
              </p>
              <div>
                <code>_seed</code> however, should be generated for each
                individual request.
              </div>
            </div>
          </div>
        </div>
        <div className="mt-5 mb-3">
          <h3 className="border-bottom border-1 pb-2">
            <a href="#ooo-contract-addresses" className="h-anchor">
              #
            </a>
            Adding some functionality
          </h3>
          <p>
            The above contract isn't very useful. While it requests a random
            number, it doesn't actually do anything with it. Let's add a few
            functions that can make use of the random number, and some support
            variables, structures and events.
          </p>
          <div
            className={`${
              isDarkMode ? "noteBlockD" : "noteBlock"
            } custom-block tip`}
          >
            <p>
              <strong className="custom-block-title">TIP</strong>
            </p>
            <div>
              None of the following variables or events are required by VOR.
              They are required only for the DnD contract's own functionality
              and for the purpose of demoing VOR.
            </div>
          </div>
        </div>
        <div className="mt-5 mb-3">
          <h3 className="border-bottom border-1 pb-2">
            <a href="#ooo-contract-addresses" className="h-anchor">
              #
            </a>
            Contract variables
          </h3>
          <p>
            We'll need a couple of simple contract variables to keep track of
            our monsters:
          </p>
          <SyntaxHighlighter language="javascript" style={syntaxStyle}>
            {` // keep track of the monsters added
    uint256 public currentMonsterId;

    // super simple monster stats
    struct Monster {
        string name;
        uint256 ac;
    }

    struct Result {
        uint256 roll;
        uint256 modified;
        string result;
        bool isRolling;
    }`}
          </SyntaxHighlighter>
          <p>
            Nothing complex here - each monster added will be assgined an
            incremental ID currentMonsterId, and have some simple stats
            associated with it. Result will be used to store that last result
            for a player/monster.
          </p>
        </div>
        <div className="mt-5 mb-3">
          <h3 className="border-bottom border-1 pb-2">
            <a href="#ooo-contract-addresses" className="h-anchor">
              #
            </a>
            Mappings{" "}
          </h3>
          <SyntaxHighlighter language="javascript" style={syntaxStyle}>
            {`// monsters held in the contract
    mapping (uint256 => Monster) public monsters;
    // player STR modifiers
    mapping (address => uint256) public strModifiers;
    // map request IDs to monster IDs
    mapping(bytes32 => uint256) public requestIdToMonsterId;
    // map request IDs to player addresses, to retrieve STR modifiers
    mapping(bytes32 => address) public requestIdToAddress;
    // store last [player][monster] results
    mapping(address => mapping(uint256 => Result)) lastResult;`}
          </SyntaxHighlighter>
          <ul>
            <li>
              <code>monsters</code> will be used to store our contracts monsters
              and their stats
            </li>
            <li>
              <code>strModifiers</code> will allow players to set their STR
              modifier, which gets added to each dice roll
            </li>
            <li>
              <code>requestIdToMonsterId</code> will temporarily store data on
              which request relates to which monster while the roll is in
              progress
            </li>
            <li>
              <code>requestIdToAddress</code> will similarly track which user
              rolled.
            </li>
            <li>
              <code>lastResult</code> will store the last roll and result for a
              player/monster
            </li>
          </ul>
        </div>
        <div className="mt-5 mb-3">
          <h3 className="border-bottom border-1 pb-2">
            <a href="#ooo-contract-addresses" className="h-anchor">
              #
            </a>
            Events{" "}
          </h3>
          <SyntaxHighlighter language="javascript" style={syntaxStyle}>
            {`event AddMonster(uint256 monsterId, string name, uint256 ac);
    event ChangeStrModifier(address player, uint256 strMod);
    event HittingMonster(uint256 monsterId, bytes32 requestId);
    event HitResult(uint256 monsterId, bytes32 requestId, address player, string result, uint256 roll, uint256 modified);`}
          </SyntaxHighlighter>
          <p>
            Again, nothing complex here - just some events that will be emitted
            during the functions we'll implement next.
          </p>
        </div>
        <div className="mt-5 mb-3">
          <h3 className="border-bottom border-1 pb-2">
            <a href="#ooo-contract-addresses" className="h-anchor">
              #
            </a>
            Monster & Player related functions{" "}
          </h3>
          <p>
            We'll add few non-VOR functions first, to support the contract owner
            adding monsters, and players editing their stats.
          </p>
          <div
            className={`${
              isDarkMode ? "noteBlockD" : "noteBlock"
            } custom-block tip`}
          >
            <p>
              <strong className="custom-block-title">TIP</strong>
            </p>
            <div>
              Neither of the two following functions are required by VOR. They
              are required only for the DnD contract's own functionality and for
              the purpose of demoing VOR.
            </div>
          </div>
        </div>
        <div className="mt-5 mb-3">
          <h3 className="border-bottom border-1 pb-2">
            <a href="#ooo-contract-addresses" className="h-anchor">
              #
            </a>
            addMonster
          </h3>
          <p>
            This will just allow the contract owner to add up to 20 monsters.
            The <code>onlyOwner</code> modifier is part of OpenZeppelin's{" "}
            <code>Ownable</code> contract.
          </p>
          <SyntaxHighlighter language="javascript" style={syntaxStyle}>
            {` function addMonster(string memory _name, uint256 _ac) external onlyOwner {
        require(nextMonsterId <= 20, "too many monsters");
        require(_ac > 0, "monster too weak");
        monsters[nextMonsterId].name = _name;
        monsters[nextMonsterId].ac = _ac;
        emit AddMonster(nextMonsterId, _name, _ac);
        nextMonsterId = nextMonsterId.add(1);
    }`}
          </SyntaxHighlighter>
          <p>
            Here, we're just taking the name and AC value of a new{" "}
            <code>monster</code> and adding it to the monsters list, then
            emitting an event. Players will select the ID of the monster they
            are fighting when calling the
            <code>rollForHit</code> function.
          </p>
        </div>
        <div className="mt-5 mb-3">
          <h3 className="border-bottom border-1 pb-2">
            <a href="#ooo-contract-addresses" className="h-anchor">
              #
            </a>
            changeStrModifier
          </h3>
          <p>
            This will just allow the contract owner to add up to 20 monsters.
            The onlyOwner modifier is part of OpenZeppelin's Ownable contract.
          </p>
          <SyntaxHighlighter language="javascript" style={syntaxStyle}>
            {` function addMonster(string memory _name, uint256 _ac) external onlyOwner {
        require(nextMonsterId <= 20, "too many monsters");
        require(_ac > 0, "monster too weak");
        monsters[nextMonsterId].name = _name;
        monsters[nextMonsterId].ac = _ac;
        emit AddMonster(nextMonsterId, _name, _ac);
        nextMonsterId = nextMonsterId.add(1);
    }`}
          </SyntaxHighlighter>
          <p>
            Here, we're just taking the name and AC value of a new monster and
            adding it to the monsters list, then emitting an event. Players will
            select the ID of the monster they are fighting when calling the
            rollForHit function.
          </p>
        </div>
        <div className="mt-5 mb-3">
          <h3 className="border-bottom border-1 pb-2">
            <a href="#ooo-contract-addresses" className="h-anchor">
              #
            </a>
            getLastResult
          </h3>
          <p>
            This function allows queries to the contract to retrieve the last
            roll result for a player/monster combo
          </p>
          <SyntaxHighlighter language="javascript" style={syntaxStyle}>
            {`function getLastResult(address _player, uint256 _monsterId) external view returns (Result memory) {
        return lastResult[_player][_monsterId];
    }`}
          </SyntaxHighlighter>
        </div>
        <div className="mt-5 mb-3">
          <h3 className="border-bottom border-1 pb-2">
            <a href="#ooo-contract-addresses" className="h-anchor">
              #
            </a>
            unstickRoll
          </h3>
          <p>Because sometimes the dice rolls under the table...</p>
          <SyntaxHighlighter language="javascript" style={syntaxStyle}>
            {` function unstickRoll(address _player, uint256 _monsterId) external onlyOwner {
        lastResult[_player][_monsterId].isRolling = false;
    }`}
          </SyntaxHighlighter>
        </div>
        <div className="mt-5 mb-3">
          <h3 className="border-bottom border-1 pb-2">
            <a href="#ooo-contract-addresses" className="h-anchor">
              #
            </a>
            Implementing VOR for Randomness
          </h3>
          <p>
            Now we have some support functions, we can implement the actual
            randomness functionality and make the contract do something. We need
            to extend and flesh out the <code>rollForHit</code> function, which
            will call the underlying <code>requestRandomness</code> function in
            the <code>VORConsumerBase</code>
            contract, and the <code>fulfillRandomness</code> function, which
            will receive and process the random number.
          </p>
          <div
            className={`${
              isDarkMode ? "noteBlockD" : "noteBlock"
            } custom-block tip`}
          >
            <p>
              <strong className="custom-block-title">TIP</strong>
            </p>
            <div>
              The following two functions <strong>are required</strong> to
              interact with VOR and request random numbers.
            </div>
          </div>
        </div>{" "}
        <div className="mt-5 mb-3">
          <h3 className="border-bottom border-1 pb-2">
            <a href="#ooo-contract-addresses" className="h-anchor">
              #
            </a>
            rollForHit{" "}
          </h3>
          <p>
            The <code>rollForHit</code> function is a wrapper around the
            required
            <code>VORConsumerBase.requestRandomness</code> function. The call to
            <code>requestRandomness</code> is required in order to initialise a
            request to a VOR Provider Oracle. Technically, this is the only
            requirement of the function, but we'll need to do some
            pre-processing in order to track results and make use of the
            returned value.
          </p>
          <SyntaxHighlighter language="javascript" style={syntaxStyle}>
            {`function rollForHit(uint256 _monsterId, uint256 _seed, bytes32 _keyHash, uint256 _fee) external returns (bytes32 requestId) {
        require(monsters[_monsterId].ac > 0, "monster does not exist");
        require(!lastResult[msg.sender][_monsterId].isRolling, "roll currently in progress");
        xFUND.transferFrom(msg.sender, address(this), _fee);
        requestId = requestRandomness(_keyHash, _fee, _seed);
        emit HittingMonster(_monsterId, requestId);
        requestIdToAddress[requestId] = msg.sender;
        requestIdToMonsterId[requestId] = _monsterId;
        lastResult[msg.sender][_monsterId].isRolling = true;
    }`}
          </SyntaxHighlighter>
          <p>
            Prior to calling <code>requestRandomness</code>, we're just ensuring
            the monster is in the contract's database, and that the
            player/monster combo does not currently have a roll in progress.
          </p>
          <p>
            The next line transfers the required fee from the player calling the
            function to this DnD contract. The VORCoordinator then transfers
            that fee from the DnD contract to itself for later forwarding to the
            VOR Provider Oracle.
          </p>
          <div
            className={`${
              isDarkMode ? "noteBlockD" : "noteBlock"
            } custom-block tip`}
          >
            <p>
              <strong className="custom-block-title">TIP</strong>
            </p>
            <p>
              This is just one method for transferring fees to the
              VORCoordinator. Another is to omit <code>xFUND.transferFrom</code>{" "}
              from your request implementation, and simply keep the contract
              topped up with xFUND (for example, if the contract owner is the
              only address that will ever call the request function). In this
              case, it would also be advisable to implement a function to
              withdraw xFUND from your contract.
            </p>
            <div>
              However, since we want each player to pay their own fees, we are
              transferring as a part of the request process.
            </div>
          </div>
          <p>
            Next, the function is making the actual{" "}
            <code>requestRandomness</code> call, which forwards the request to
            the <code>VORCoordinator</code>. It returns the generated{" "}
            <code>requestId</code> which we then use to map some data about the
            request:
          </p>
          <ul>
            <li>the address of the player who made the request</li>
            <li>the monster th player is fighting.</li>
          </ul>
          <p>
            When the request is fulfilled, the <code>requestId</code> is
            included in the fulfilment so that we can retrieve any data
            associated with the request.
          </p>
          <p>
            Finally, we set <code>isRolling</code> for the current
            player/monster combo to prevent any further requests on this combo
            until it's fulfilled
          </p>
        </div>{" "}
        <div className="mt-5 mb-3">
          <h3 className="border-bottom border-1 pb-2">
            <a href="#ooo-contract-addresses" className="h-anchor">
              #
            </a>
            fulfillRandomness{" "}
          </h3>
          <p>
            The <code>fulfillRandomness</code> overrides the{" "}
            <code>virtual</code> function in
            <code>VORConsumerBase</code>. It is the function that will receive
            the random number and ultimately process it.
          </p>
          <div
            className={`${
              isDarkMode ? "noteBlockD" : "noteBlock"
            } custom-block tip`}
          >
            <p>
              <strong className="custom-block-title">TIP</strong>
            </p>
            <p>
              Whilst the <code>requestRandomness</code> function can be wrapped
              around an arbitrary function, <code>fulfillRandomness</code> must
              be implemented as
            </p>
            <p>
              <code>
                function fulfillRandomness(bytes32 requestId, uint256
                randomness)
              </code>
            </p>
            <p>
              This is because the <code>VORCoordinator</code>, and{" "}
              <code>VORConsumerBase</code> return data with specific parameters,
              and expect this function to be defined as this in order to be able
              to correctly fulfil the request.
            </p>
            <div>The internals of the function contain anything, however.</div>
          </div>
          <SyntaxHighlighter language="javascript" style={syntaxStyle}>
            {` function fulfillRandomness(bytes32 _requestId, uint256 _randomness) internal override {
        uint256 monsterId = requestIdToMonsterId[_requestId];
        address player = requestIdToAddress[_requestId];
        uint256 strModifier = strModifiers[player];
        uint256 roll = _randomness.mod(20).add(1);
        uint256 modified = roll.add(strModifier);
        string memory res = "miss";

        // Critical hit!
        if(roll == 20) {
            res = "nat20";
        } else if (roll == 1) {
            res = "nat1";
        } else {
            // Check roll + STR modifier against monster's AC
            if(modified >= monsters[monsterId].ac) {
                res = "hit";
            } else {
                res = "miss";
            }
        }
        emit HitResult(monsterId, _requestId, player, res, roll, modified);
  
        // store the results
        lastResult[player][monsterId].result = res;
        lastResult[player][monsterId].roll = roll;
        lastResult[player][monsterId].modified = modified;
        lastResult[player][monsterId].isRolling = false;

        // clean up
        delete requestIdToMonsterId[_requestId];
        delete requestIdToAddress[_requestId];
    }`}
          </SyntaxHighlighter>
          <p>
            The first three lines of our function are just retrieving some data
            related to the request. This data was stored when the request was
            initialised. It's simply getting the ID of the monster being hit,
            the player who is hitting it, and then from that, deriving that
            player's STR modifier.
          </p>
          <p>
            Next, the <code>_randomness</code> value returned from the VOR
            Provider Oracle is converted into a value between 1 and 20,
            simulating the roll of a d20.
          </p>
          <p>
            The player's STR modifier is added to this value, and then checking
            whether the player rolled high enough to hit the selected monster.
          </p>
          <p>
            A roll of 20 is a Natural 20, and always hits (with crit!). A roll
            of 1 is a Natural 1, and always misses (boo!).
          </p>
          <p>
            Otherwise, we check to see if the <code>roll + STR</code> modifier
            is enough to hit the monster.
          </p>
          <p>
            The result is emitted in the <code>HitResult</code> event, and
            stored in
            <code>lastResult</code>.
          </p>
        </div>
        <div className="mt-5 mb-3">
          <h3 className="border-bottom border-1 pb-2">
            <a href="#ooo-contract-addresses" className="h-anchor">
              #
            </a>
            Helper function(s)
          </h3>
          <p>
            The final function we'll write uses the
            <code>_increaseVorCoordinatorAllowance</code> helper function
            included in
            <code>VORConsumerBase</code>. Its role is to permit the{" "}
            <code>VORCoordinator</code> smart contract to spend xFUNDMOCK on
            behalf of our smart contract, since the <code>VORCoordinator</code>{" "}
            needs to be able to transfer fees when each request for randomness
            is made.
          </p>
          <p>
            For this function, we'll also use the OpenZeppelin{" "}
            <code>Ownable</code>
            contract's <code>onlyOwner</code> modifier to ensure that only the
            contract owner can run this function
          </p>
          <SyntaxHighlighter language="javascript" style={syntaxStyle}>
            {`function increaseVorAllowance(uint256 _amount) external onlyOwner {
        _increaseVorCoordinatorAllowance(_amount);
    }`}
          </SyntaxHighlighter>
          <p>
            This just calls{" "}
            <code>VORConsumerBase._increaseVorCoordinatorAllowance</code>
            function, which in turn informs the <code>xFUNDMOCK</code> smart
            contract that we're allowing <code>VORCoordinator</code> to spend{" "}
            <code>DnD</code>'s <code>xFUNDMOCK</code> tokens to pay for fees.
          </p>
          <div
            className={`${
              isDarkMode ? "noteBlockD" : "noteBlock"
            } custom-block tip`}
          >
            <p>
              <strong className="custom-block-title">Note</strong>
            </p>
            <div>
              The <code>VORConsumerBase</code> contract contains other helper
              functions, which can also be wrapped in a protected function in
              your own contract. See{" "}
              <a
                href="https://github.com/unification-com/xfund-vor/blob/main/contracts/examples"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://github.com/unification-com/xfund-vor/blob/main/contracts/examples
              </a>{" "}
              for implementation examples.
            </div>
          </div>
        </div>
        <div className="mt-5 mb-3">
          <h3 className="border-bottom border-1 pb-2">
            <a href="#ooo-contract-addresses" className="h-anchor">
              #
            </a>
            Final contract
          </h3>
          <div
            className={`${
              isDarkMode ? "noteBlockD" : "noteBlock"
            } custom-block tip`}
          >
            <p>
              <strong className="custom-block-title">TIP</strong>
            </p>
            <div>
              Check out{" "}
              <a
                href="https://github.com/unification-com/vor-demos/blob/main/contracts/DnD.sol"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://github.com/unification-com/vor-demos/blob/main/contracts/DnD.sol
              </a>
              for the latest version of this demo contract.
            </div>
          </div>
          <p>The final contract should look something like this:</p>
          <SyntaxHighlighter language="javascript" style={syntaxStyle}>
            {`// SPDX-License-Identifier: MIT
pragma solidity 0.6.12;
pragma experimental ABIEncoderV2;

import "@openzeppelin/contracts/math/SafeMath.sol";
import "@openzeppelin/contracts/access/Ownable.sol";
//import "@unification-com/xfund-vor/contracts/VORConsumerBase.sol";
import "../../xfund-vor/contracts/VORConsumerBase.sol";

/** ****************************************************************************
 * @notice Extremely simple DnD roll D20 to Hit using VOR
 * *****************************************************************************
 * @dev The contract owner can add up to 20 monsters. Players can modify their STR
 * modifier, which is pinned to their address. Players call the rollForHit function
 * and pay the associated xFUND fee to roll the D20. The result is returned in
 * fulfillRandomness, which calculates if the player crits, hits or misses.
 */
contract DnD is Ownable, VORConsumerBase {
    using SafeMath for uint256;

    // keep track of the monsters
    uint256 public nextMonsterId;

    // super simple monster stats
    struct Monster {
        string name;
        uint256 ac;
    }

    struct Result {
        uint256 roll;
        uint256 modified;
        string result;
        bool isRolling;
    }

    // monsters held in the contract
    mapping (uint256 => Monster) public monsters;
    // player STR modifiers
    mapping (address => uint256) public strModifiers;
    // map request IDs to monster IDs
    mapping(bytes32 => uint256) public requestIdToMonsterId;
    // map request IDs to player addresses, to retrieve STR modifiers
    mapping(bytes32 => address) public requestIdToAddress;
    // store last [player][monster] results
    mapping(address => mapping(uint256 => Result)) lastResult;

    // Some useful events to track
    event AddMonster(uint256 monsterId, string name, uint256 ac);
    event ChangeStrModifier(address player, uint256 strMod);
    event HittingMonster(uint256 monsterId, bytes32 requestId);
    event HitResult(uint256 monsterId, bytes32 requestId, address player, string result, uint256 roll, uint256 modified);

    /**
    * @notice Constructor inherits VORConsumerBase
    *
    * @param _vorCoordinator address of the VOR Coordinator
    * @param _xfund address of the xFUND token
    */
    constructor(address _vorCoordinator, address _xfund)
    public
    VORConsumerBase(_vorCoordinator, _xfund) {
        nextMonsterId = 1;
    }

    /**
    * @notice addMonster can be called by the owner to add a new monster
    *
    * @param _name string name of the monster
    * @param _ac uint256 AC of the monster
    */
    function addMonster(string memory _name, uint256 _ac) external onlyOwner {
        require(nextMonsterId <= 20, "too many monsters");
        require(_ac > 0, "monster too weak");
        monsters[nextMonsterId].name = _name;
        monsters[nextMonsterId].ac = _ac;
        emit AddMonster(nextMonsterId, _name, _ac);
        nextMonsterId = nextMonsterId.add(1);
    }

    /**
    * @notice changeStrModifier can be called by anyone to change their STR modifier
    *
    * @param _strMod uint256 STR modifier of player
    */
    function changeStrModifier(uint256 _strMod) external {
        require(_strMod <= 5, "player too strong");
        strModifiers[msg.sender] = _strMod;
        emit ChangeStrModifier(msg.sender, _strMod);
    }

    /**
    * @notice rollForHit anyone can call to roll the D20 for hit. Caller (msg.sender)
    * pays the xFUND fees for the request.
    *
    * @param _monsterId uint256 Id of the monster the caller is fighting
    * @param _seed uint256 seed for the randomness request. Gets mixed in with the blockhash of the block this Tx is in
    * @param _keyHash bytes32 key hash of the provider caller wants to fulfil the request
    * @param _fee uint256 required fee amount for the request
    */
    function rollForHit(uint256 _monsterId, uint256 _seed, bytes32 _keyHash, uint256 _fee) external returns (bytes32 requestId) {
        require(monsters[_monsterId].ac > 0, "monster does not exist");
        require(!lastResult[msg.sender][_monsterId].isRolling, "roll currently in progress");
        // Note - caller must have increased xFUND allowance for this contract first.
        // Fee is transferred from msg.sender to this contract. The VORCoordinator.requestRandomness
        // function will then transfer from this contract to itself.
        // This contract's owner must have increased the VORCoordnator's allowance for this contract.
        xFUND.transferFrom(msg.sender, address(this), _fee);
        requestId = requestRandomness(_keyHash, _fee, _seed);
        emit HittingMonster(_monsterId, requestId);
        requestIdToAddress[requestId] = msg.sender;
        requestIdToMonsterId[requestId] = _monsterId;
        lastResult[msg.sender][_monsterId].isRolling = true;
    }

    /**
     * @notice Callback function used by VOR Coordinator to return the random number
     * to this contract.
     * @dev The random number is used to simulate a D20 roll. Result is emitted as follows:
     * 1: Natural 1...
     * 20: Natural 20!
     * roll + strModifier >= monster AC: hit
     * roll + strModifier < monster AC: miss
     *
     * @param _requestId bytes32
     * @param _randomness The random result returned by the oracle
     */
    function fulfillRandomness(bytes32 _requestId, uint256 _randomness) internal override {
        uint256 monsterId = requestIdToMonsterId[_requestId];
        address player = requestIdToAddress[_requestId];
        uint256 strModifier = strModifiers[player];
        uint256 roll = _randomness.mod(20).add(1);
        uint256 modified = roll.add(strModifier);
        string memory res = "miss";

        // Critical hit!
        if(roll == 20) {
            res = "nat20";
        } else if (roll == 1) {
            res = "nat1";
        } else {
            // Check roll + STR modifier against monster's AC
            if(modified >= monsters[monsterId].ac) {
                res = "hit";
            } else {
                res = "miss";
            }
        }
        emit HitResult(monsterId, _requestId, player, res, roll, modified);

        // store the results
        lastResult[player][monsterId].result = res;
        lastResult[player][monsterId].roll = roll;
        lastResult[player][monsterId].modified = modified;
        lastResult[player][monsterId].isRolling = false;

        // clean up
        delete requestIdToMonsterId[_requestId];
        delete requestIdToAddress[_requestId];
    }

    /**
     * @notice getLastResult returns the last result for a specified player/monsterId.
     *
     * @param _player address address of player
     * @param _monsterId uint256 id of monster
     */
    function getLastResult(address _player, uint256 _monsterId) external view returns (Result memory) {
        return lastResult[_player][_monsterId];
    }

    /**
    * @notice unstickRoll allows contract owner to unstick a roll when a request is not fulfilled
    *
    * @param _player address address of player
    * @param _monsterId uint256 id of monster
    */
    function unstickRoll(address _player, uint256 _monsterId) external onlyOwner {
        lastResult[_player][_monsterId].isRolling = false;
    }

    /**
     * @notice Example wrapper function for the VORConsumerBase increaseVorCoordinatorAllowance function.
     * @dev Wrapped around an Ownable modifier to ensure only the contract owner can call it.
     * @dev Allows contract owner to increase the xFUND allowance for the VORCoordinator contract
     * @dev enabling it to pay request fees on behalf of this contract.
     *
     * @param _amount uint256 amount to increase allowance by
     */
    function increaseVorAllowance(uint256 _amount) external onlyOwner {
        _increaseVorCoordinatorAllowance(_amount);
    }
}
`}
          </SyntaxHighlighter>
          <p>
            Next, we'll look at how to interact with the contract, request
            randomness and view the results.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Integration;
