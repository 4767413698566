import React from "react";
import "./URLShow.scss";

// Reusable Card Component
const Card = ({
  isDarkMode,
  link,
  icon,
  title,
  token = "",
  noFilter,
  titleLogo,
  linkIcon,
  textIcon,
  listdata,
}) => {
  // const textColor = isDarkMode ? "color-ffff" : "color-2749";

  const getTitleText = (title) => {
    switch (title) {
      case "UNoDE":
        return "Go to Dashboard";
      case "TestNet Faucet":
        return "Go to Faucet";
      default:
        return "Go to GitHub Repository";
    }
  };

  return (
    <div id="urlshowcard">
      <div
        className={`urlshowcard-container ${isDarkMode ? "" : "b-dee3"} ${
          isDarkMode ? "" : "cp-20"
        } br-20 ${isDarkMode ? "block3" : "card-block"}`}
      >
        <div className={`${isDarkMode ? "dark-block3" : ""}`}>
          <div
            className="fa-center gap-3 flex-nowrap pointer"
            onClick={() =>
              window.open(token ? `${link}?id=${token}` : link, "_blank")
            }
          >
            <div className="d-flex flex-column flex-nowrap wp-100">
              {title === "UNoDE" && (
                // <div className="w-140 h-36 cmb-30">
                <div className="cmb-30 wp-70">
                  <img
                    src={titleLogo}
                    alt=""
                    className="fit-image"
                    loading="lazy"
                    style={{
                      filter: noFilter
                        ? ""
                        : isDarkMode
                        ? "invert(1)"
                        : "invert(0)",
                    }}
                  />
                </div>
              )}
              {title !== "UNoDE" && (
                <div className="cmb-30">
                  <div className="fa-center gap-3 flex-nowrap">
                    {textIcon && (
                      <div className="flex-shrink-0 w-124 h-124">
                        <img
                          src={textIcon}
                          alt=""
                          className="fit-image"
                          loading="lazy"
                        />
                      </div>
                    )}
                    <div
                      className={`text-107-500`}
                      style={{
                        background: "linear-gradient(90deg, #ff6edd, #6b78ff)",
                        WebkitBackgroundClip: "text",
                        WebkitTextFillColor: "transparent",
                      }}
                    >
                      {title}
                    </div>
                  </div>
                </div>
              )}
              {/* <div className="cms-10">
                <ul
                  style={{
                    listStyleType: "none",
                    paddingLeft: "20px",
                    color: isDarkMode ? "#ddd" : "#000000d1",
                  }}
                >
                  {listdata?.map((item, index) => (
                    <li
                      key={index}
                      className="text-20-500"
                      style={{
                        marginBottom: "8px",
                        position: "relative",
                      }}
                    >
                      <div className="fa-center flex-nowrap gap-3">
                        <div className="w-20 h-20 f-center flex-shrink-0">
                          <img alt="" src={item?.icon} className="fit-image" />
                        </div>
                        <div>{item?.title}</div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div> */}
              <div
                className="link-container fb-center flex-nowrap"
                style={{ justifyContent: "flex-end" }}
                onClick={() =>
                  window.open(token ? `${link}?id=${token}` : link, "_blank")
                }
              >
                <span
                  style={{
                    marginRight: "8px",
                    color: isDarkMode ? "#007bff" : "#007bff",
                    fontWeight: "bold",
                    cursor: "pointer",
                  }}
                  className="textblock text-20-600"
                >
                  {getTitleText(title)}
                </span>
                <img
                  src={linkIcon}
                  alt="Link Icon"
                  className="fit-image"
                  loading="lazy"
                  style={{
                    filter: isDarkMode ? "invert(1)" : "invert(0)",
                    width: "14px",
                    height: "14px",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
