import "./SyntexHighlight.scss";
import SyntaxHighlighter from "react-syntax-highlighter";
import { atomOneDark } from "react-syntax-highlighter/dist/esm/styles/hljs";

const SyntexHighlight = ({ text }) => {
  const syntaxStyle = atomOneDark;

  return (
    <div id="syntexhighlight-container">
      <SyntaxHighlighter language="bash" style={syntaxStyle}>
        {text}
      </SyntaxHighlighter>
    </div>
  );
};

export default SyntexHighlight;
