// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#promptalert-container {
  position: fixed;
  bottom: 0%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1111;
}
#promptalert-container .alert-dismissible {
  padding-right: 5rem !important;
}
#promptalert-container .alert {
  position: relative;
  font-size: 14px;
  border-radius: 8px !important;
  border: none !important;
  box-shadow: 0px 2px 8px rgba(40, 41, 61, 0.08), 0px 20px 32px rgba(96, 97, 112, 0.24);
  animation-name: bounceIn;
  animation-duration: 0.75s;
  animation-duration: 1s;
  animation-fill-mode: both;
}
#promptalert-container .alert .close {
  position: absolute;
  top: 50%;
  right: 14px;
  transform: translate(-50%, -50%);
  background: none !important;
  border: none !important;
}
#promptalert-container .alert .close .bi-x-lg::before {
  line-height: 0%;
  font-weight: 700 !important;
}
#promptalert-container .alert .fa {
  margin-right: 0.3em;
}
#promptalert-container .alert-msg-success {
  border-left: 4px solid var(--8754) !important;
}
#promptalert-container .alert-msg-danger {
  border-left: 4px solid var(--dc35) !important;
}`, "",{"version":3,"sources":["webpack://./src/components/layouts/Promptalert/Promptalert.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,UAAA;EACA,SAAA;EACA,gCAAA;EACA,aAAA;AACF;AACE;EACE,8BAAA;AACJ;AACE;EACE,kBAAA;EACA,eAAA;EACA,6BAAA;EACA,uBAAA;EACA,qFAAA;EAIA,wBAAA;EAEA,yBAAA;EAEA,sBAAA;EAEA,yBAAA;AADJ;AAGE;EACE,kBAAA;EACA,QAAA;EACA,WAAA;EACA,gCAAA;EACA,2BAAA;EACA,uBAAA;AADJ;AAEI;EACE,eAAA;EACA,2BAAA;AAAN;AAGE;EACE,mBAAA;AADJ;AAIE;EACE,6CAAA;AAFJ;AAKE;EACE,6CAAA;AAHJ","sourcesContent":["#promptalert-container {\r\n  position: fixed;\r\n  bottom: 0%;\r\n  left: 50%;\r\n  transform: translate(-50%, -50%);\r\n  z-index: 1111;\r\n\r\n  .alert-dismissible {\r\n    padding-right: 5rem !important;\r\n  }\r\n  .alert {\r\n    position: relative;\r\n    font-size: 14px;\r\n    border-radius: 8px !important;\r\n    border: none !important;\r\n    box-shadow: 0px 2px 8px rgba(40, 41, 61, 0.08),\r\n      0px 20px 32px rgba(96, 97, 112, 0.24);\r\n\r\n    -webkit-animation-name: bounceIn;\r\n    animation-name: bounceIn;\r\n    -webkit-animation-duration: 0.75s;\r\n    animation-duration: 0.75s;\r\n    -webkit-animation-duration: 1s;\r\n    animation-duration: 1s;\r\n    -webkit-animation-fill-mode: both;\r\n    animation-fill-mode: both;\r\n  }\r\n  .alert .close {\r\n    position: absolute;\r\n    top: 50%;\r\n    right: 14px;\r\n    transform: translate(-50%, -50%);\r\n    background: none !important;\r\n    border: none !important;\r\n    .bi-x-lg::before {\r\n      line-height: 0%;\r\n      font-weight: 700 !important;\r\n    }\r\n  }\r\n  .alert .fa {\r\n    margin-right: 0.3em;\r\n  }\r\n\r\n  .alert-msg-success {\r\n    border-left: 4px solid var(--8754) !important;\r\n  }\r\n\r\n  .alert-msg-danger {\r\n    border-left: 4px solid var(--dc35) !important;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
