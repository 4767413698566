// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#paymentstream-container {
  height: calc(100vh - 80px);
  overflow: auto;
}
#paymentstream-container .card {
  border-color: var(--is-border-color);
}
#paymentstream-container .address {
  border: 1px solid var(--is-border-color);
  padding: 6px 30px;
  border-radius: 20px;
}`, "",{"version":3,"sources":["webpack://./src/pages/PaymentStream/PaymentStream.scss"],"names":[],"mappings":"AAAA;EACE,0BAAA;EACA,cAAA;AACF;AAAE;EACE,oCAAA;AAEJ;AAAE;EACE,wCAAA;EACA,iBAAA;EACA,mBAAA;AAEJ","sourcesContent":["#paymentstream-container {\r\n  height: calc(100vh - 80px);\r\n  overflow: auto;\r\n  .card {\r\n    border-color: var(--is-border-color);\r\n  }\r\n  .address {\r\n    border: 1px solid var(--is-border-color);\r\n    padding: 6px 30px;\r\n    border-radius: 20px;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
