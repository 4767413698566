import "./XFundEmissions.scss";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import xFundImg from "./xFundImg.png";

const XFundEmissions = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const reduxData = useSelector((state) => state.global);
  const { isDarkMode } = reduxData || {};
  const [pageLoading, setPageLoading] = useState(false);

  return (
    <div
      id="xfundemissions-container"
      className={`cp-38 ${isDarkMode ? "custom-scroll-dark" : "custom-scroll"}`}
    >
      {pageLoading ? (
        <>
          <div
            className={`${
              isDarkMode ? "skeleton-dark" : "skeleton"
            } h-55 br-10`}
          />
          <div className="row cmt-26">
            {[...Array(4)].map((_, index) => (
              <div key={index} className="col-xl-3 col-lg-4 col-sm-6 col-12">
                <div
                  className={`${
                    isDarkMode ? "skeleton-dark" : "skeleton"
                  } node-container h-300 border-0`}
                />
              </div>
            ))}
          </div>
        </>
      ) : (
        <>
          <div>
            <img
              src={xFundImg}
              alt="demo"
              style={{
                width: "100%",
                height: "100%",
                marginRight: "8px",
              }}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default XFundEmissions;
