// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#text-area-container textarea {
  width: 100%;
  border-radius: 8px;
  outline: none;
  display: flex;
  flex-direction: column;
  border: 1px solid var(--dee3);
  padding: 13px 16px;
  font-size: 14px;
  font-weight: 400;
  font-family: "f-400";
  line-height: 19.5px;
}
#text-area-container :disabled {
  background-color: #e6e6e6;
  color: #212121;
}

#text-area-container-dark textarea {
  width: 100%;
  border-radius: 8px;
  outline: none;
  display: flex;
  flex-direction: column;
  border: 1px solid var(--4d6c);
  padding: 13px 16px;
  font-size: 14px;
  font-weight: 400;
  font-family: "f-400";
  line-height: 19.5px;
  color: var(--ffff);
  background-color: var(--4d6c);
}
#text-area-container-dark textarea::placeholder {
  color: var(--ffff);
}
#text-area-container-dark :disabled {
  background-color: #e6e6e6;
  color: #212121;
}`, "",{"version":3,"sources":["webpack://./src/components/inputs/TextArea/TextArea.scss"],"names":[],"mappings":"AACE;EACE,WAAA;EACA,kBAAA;EACA,aAAA;EACA,aAAA;EACA,sBAAA;EACA,6BAAA;EACA,kBAAA;EACA,eAAA;EACA,gBAAA;EACA,oBAAA;EACA,mBAAA;AAAJ;AAEE;EACE,yBAAA;EACA,cAAA;AAAJ;;AAIE;EACE,WAAA;EACA,kBAAA;EACA,aAAA;EACA,aAAA;EACA,sBAAA;EACA,6BAAA;EACA,kBAAA;EACA,eAAA;EACA,gBAAA;EACA,oBAAA;EACA,mBAAA;EACA,kBAAA;EACA,6BAAA;AADJ;AAEI;EACE,kBAAA;AAAN;AAGE;EACE,yBAAA;EACA,cAAA;AADJ","sourcesContent":["#text-area-container {\r\n  textarea {\r\n    width: 100%;\r\n    border-radius: 8px;\r\n    outline: none;\r\n    display: flex;\r\n    flex-direction: column;\r\n    border: 1px solid var(--dee3);\r\n    padding: 13px 16px;\r\n    font-size: 14px;\r\n    font-weight: 400;\r\n    font-family: \"f-400\";\r\n    line-height: 19.5px;\r\n  }\r\n  :disabled {\r\n    background-color: #e6e6e6;\r\n    color: #212121;\r\n  }\r\n}\r\n#text-area-container-dark {\r\n  textarea {\r\n    width: 100%;\r\n    border-radius: 8px;\r\n    outline: none;\r\n    display: flex;\r\n    flex-direction: column;\r\n    border: 1px solid var(--4d6c);\r\n    padding: 13px 16px;\r\n    font-size: 14px;\r\n    font-weight: 400;\r\n    font-family: \"f-400\";\r\n    line-height: 19.5px;\r\n    color: var(--ffff);\r\n    background-color: var(--4d6c);\r\n    &::placeholder {\r\n      color: var(--ffff);\r\n    }\r\n  }\r\n  :disabled {\r\n    background-color: #e6e6e6;\r\n    color: #212121;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
