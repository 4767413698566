import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { icons } from "utils/constants";
import { useSelector } from "react-redux";
import "./Sidebar.scss";

const Sidebar = ({ handleLogout }) => {
  const navigate = useNavigate();
  // const [show, seShow] = useState(false);
  const reduxData = useSelector((state) => state.global);
  const { isDarkMode } = reduxData || {};
  const scrollClass = isDarkMode ? "custom-scroll-dark" : "custom-scroll";
  const [expandedMenu, setExpandedMenu] = useState(null);

  const sidebarOption = [
    {
      title: "Main Dashboard",
      url: "main-dashboard",
      menuIcons: icons.dashboard,
      activeMenuIcons: isDarkMode
        ? icons.activedashBoardDark
        : icons.activeDashboard,
      show: true,
    },
    {
      title: "UNoDE",
      url: "unode",
      menuIcons: icons.dashboard,
      activeMenuIcons: isDarkMode
        ? icons.activedashBoardDark
        : icons.activeDashboard,
      show: true,
      subMenu: [{ title: "Dashboard", url: "unode/dashboard" }],
    },
    {
      title: "OoO",
      url: "ooo",
      menuIcons: icons.dashboard,
      activeMenuIcons: isDarkMode
        ? icons.activedashBoardDark
        : icons.activeDashboard,
      show: true,
      subMenu: [
        { title: "GitHub Repository", url: "ooo/dashboard" },
        { title: "Smart Contracts", url: "ooo/smart-ontracts" },
        { title: "Quickstart", url: "ooo/ooo-quickstart" },
        { title: "Implementation", url: "ooo/ooo-implementation" },
        { title: "Initialisation ", url: "ooo/ooo-initialisation" },
        { title: "Data API Guide", url: "ooo/ooo-data-api" },
      ],
    },
    {
      title: "VOR",
      url: "vor",
      menuIcons: icons.dashboard,
      activeMenuIcons: isDarkMode
        ? icons.activedashBoardDark
        : icons.activeDashboard,
      show: true,
      subMenu: [
        { title: "GitHub Repository", url: "vor/dashboard" },
        { title: "Integration ", url: "vor/integration" },
      ],
    },
    {
      title: "Payment Streams",
      url: "payment-streams",
      menuIcons: icons.dashboard,
      activeMenuIcons: isDarkMode
        ? icons.activedashBoardDark
        : icons.activeDashboard,
      show: true,
    },
    {
      title: "Fundjs",
      url: "fundjs",
      menuIcons: icons.dashboard,
      activeMenuIcons: isDarkMode
        ? icons.activedashBoardDark
        : icons.activeDashboard,
      show: true,
      subMenu: [
        { title: "GitHub Repository", url: "fundjs/dashboard" },
        { title: "Code snippets", url: "fundjs/code-snippets" },
      ],
    },
    {
      title: "TestNet Faucet",
      url: "testnet-faucet",
      menuIcons: icons.dashboard,
      activeMenuIcons: isDarkMode
        ? icons.activedashBoardDark
        : icons.activeDashboard,
      show: true,
      subMenu: [{ title: "Dashboard", url: "testnet-faucet/dashboard" }],
    },
    {
      title: "WrkChain",
      url: "wrk-chain",
      menuIcons: icons.dashboard,
      activeMenuIcons: isDarkMode
        ? icons.activedashBoardDark
        : icons.activeDashboard,
      show: true,
    },
    {
      title: "BEACON",
      url: "beacon",
      menuIcons: icons.dashboard,
      activeMenuIcons: isDarkMode
        ? icons.activedashBoardDark
        : icons.activeDashboard,
      show: true,
      subMenu: [
        // { title: "DashBoard", url: "beacon/dashboard" },
        // { title: "Integration", url: "beacon/integration" },
        { title: "Code snippets", url: "beacon/code-snippets" },
        { title: "xFUND Emissions", url: "beacon/xfund-emissions" },
      ],
    },
  ];

  const toggleMenu = (index) => {
    setExpandedMenu(expandedMenu === index ? null : index);
  };

  // useEffect(() => {
  //   setTimeout(() => {
  //     seShow(true);
  //   }, 1000);
  // }, []);

  return (
    <div id={`${isDarkMode ? "sidebar-container-dark" : "sidebar-container"}`}>
      <div className={`cpt-22 cpb-22 ${isDarkMode ? "bb-4d6c" : " bb-dee3"}`}>
        <div
          className="h-35 pointer position-relative"
          onClick={() => {
            navigate("/main-dashboard");
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ marginRight: "5px", paddingLeft: "10px" }}>
              <img
                src={icons.unificationIcon}
                alt="logo"
                style={{ maxWidth: "45px", height: "auto" }}
              />
            </div>
            <h2
              style={{
                background: "linear-gradient(90deg, #ff6edd, #6b78ff)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              UNIFICATION
            </h2>
          </div>
          <p
            className={`position-absolute ${
              isDarkMode ? "color-ffff" : "color-7e91"
            } text-12-400 version mt-3`}
          >
            v0.05
          </p>
        </div>
      </div>
      <div className={`flex-grow-1 d-flex flex-column sideBox ${scrollClass}`}>
        <div className={`side-option-container cpt-20 flex-grow-1`}>
          {sidebarOption.map((elm, index) => {
            const {
              title,
              url,
              menuIcons,
              activeMenuIcons,
              show,
              isMark,
              subMenu,
            } = elm;
            const isActiveMenu = window.location.pathname.includes(url);
            return (
              <div key={index}>
                <div
                  className={`side-menu-block ${
                    isActiveMenu ? "active-side-menu" : ""
                  } ${show ? "" : "d-none"}`}
                  onClick={() => {
                    if (subMenu) {
                      toggleMenu(index);
                      navigate(`/${subMenu[0].url}`);
                    } else if (!isActiveMenu) {
                      navigate(`/${url}`);
                      setExpandedMenu(null);
                    }
                  }}
                >
                  <span className="side-icon-block">
                    <img
                      src={isActiveMenu ? activeMenuIcons : menuIcons}
                      alt="menuIcons"
                      className="fit-image"
                    />
                  </span>
                  <span className="side-title-block">{title}</span>
                  {isMark && <span className="mark-block" />}

                  {/* Submenu toggle indicator */}
                  {/* {subMenu && (
                    <span className="submenu-toggle">
                      <img
                        src={
                          expandedMenu === index
                            ? icons.downArrow
                            : icons.upArrow
                        }
                        style={{ width: "16px", height: "16px" }}
                        alt="submenu toggle icon"
                        className="submenu-toggle-icon"
                      />
                    </span>
                  )} */}
                </div>
                {/* Render submenu if expanded */}

                {subMenu && expandedMenu === index && (
                  <div
                    style={{
                      borderLeft: `2px solid ${
                        isDarkMode ? "#b0b0b0" : "#ccc"
                      }`,
                      p: 0,
                      marginLeft: "50px",
                    }}
                  >
                    {subMenu.map((subItem, subIndex) => {
                      const isSubmenuActive = window.location.pathname.includes(
                        subItem.url
                      );
                      return (
                        <div
                          key={subIndex}
                          className={`submenu-item ${
                            isSubmenuActive ? "active" : ""
                          } ${isDarkMode ? "dark-mode" : "light-mode"}`}
                          onClick={() => navigate(`/${subItem.url}`)}
                        >
                          <span
                            className={`submenu-dot ${
                              isDarkMode ? "dark-dot" : "light-dot"
                            }`}
                            style={{
                              display: "inline-block",
                              width: "10px",
                              height: "10px",
                              borderRadius: "50%",
                              backgroundColor: isSubmenuActive
                                ? "#6c56ff"
                                : isDarkMode
                                ? "#b0b0b0"
                                : "#777e91",
                              marginRight: "10px",
                              transition: "background-color 0.3s ease",
                            }}
                          ></span>
                          {subItem.title}
                        </div>
                      );
                    })}
                  </div>
                )}
                <hr
                  style={{
                    borderTopColor: "gray",
                    padding: 0,
                    margin: "0 auto",
                    width: "90%",
                  }}
                />
              </div>
            );
          })}
        </div>
        <div className="cps-36 pb-3 cpe-20">
          {/* {!isSubscription && show && <SubscriptionButton />} */}
          <div
            className="fa-center un pt-3 pb-3 pointer mt-1"
            onClick={handleLogout}
          >
            <span className="h-22">
              <img
                src={isDarkMode ? icons.logoutDark : icons.logout}
                alt="logout"
                className="fit-image"
              />
            </span>
            <span
              className={`text-18-400 ${
                isDarkMode ? "color-c9c9" : "color-7e91"
              } ms-2`}
            >
              Log out
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
