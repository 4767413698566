import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { icons } from "utils/constants";
import { Card, Container } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

import "./OTP.scss";
import { Button } from "components";
import {
  handleLoginApi,
  handleResendOtpApi,
  handleVerifyOtpApi,
} from "store/globalSlice";

// Validation schema using Yup
const validationSchema = Yup.object().shape({
  otp: Yup.string()
    .length(6, "OTP must be exactly 6 digits")
    .required("OTP is required"),
});

const OTP = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [resendTimer, setResendTimer] = useState(0);
  const { isDarkMode } = useSelector((state) => state.global) || {};

  const navigate = useNavigate();

  const location = useLocation();
  const { email, password } = location.state || {};

  const OTP_RESEND_INTERVAL = 1 * 60; // 5 minutes in seconds

  useEffect(() => {
    if (!email || !password) {
      navigate("/signup");
    }

    // Check for saved OTP sent time in localStorage
    const savedTime = localStorage.getItem("otpSentTime");
    if (savedTime) {
      const elapsedTime =
        Math.floor(Date.now() / 1000) - parseInt(savedTime, 10);
      const remainingTime = OTP_RESEND_INTERVAL - elapsedTime;

      if (remainingTime > 0) {
        setResendTimer(remainingTime);
      }
    }
  }, [email, password]);

  useEffect(() => {
    let timer;
    if (resendTimer > 0) {
      timer = setInterval(() => {
        setResendTimer((prev) => {
          if (prev <= 1) {
            clearInterval(timer);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [resendTimer]);

  const handleVerifyOtp = async (values) => {
    try {
      setLoading(true);

      const payload = {
        email,
        password,
        otp: values.otp,
      };

      const loginPayload = { email, password };

      const res = await dispatch(handleVerifyOtpApi(payload));

      if (res?.status === 200) {
        const loginRes = await dispatch(handleLoginApi(loginPayload));

        if (loginRes?.status === 200) {
          navigate("/main-dashboard");
        } else {
          setError(loginRes?.data?.error || "Login failed. Please try again.");
        }
      } else {
        setError(
          res?.data?.error || "OTP verification failed. Please try again."
        );
      }
    } catch (err) {
      setError("An unexpected error occurred. Please try again.");
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleResendOtp = async () => {
    try {
      setLoading(true);

      const payload = { email };
      const res = await dispatch(handleResendOtpApi(payload));

      if (res?.status === 200) {
        // Save the current timestamp in seconds
        localStorage.setItem("otpSentTime", Math.floor(Date.now() / 1000));
        setResendTimer(OTP_RESEND_INTERVAL);
      } else {
        setError(res?.data?.error || "OTP resend failed. Please try again.");
      }
    } catch (err) {
      setError("An unexpected error occurred. Please try again.");
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}:${secs < 10 ? "0" : ""}${secs}`;
  };

  return (
    <>
      <div
        className={`otp-page ${isDarkMode ? "dark-mode" : "light-mode"}`}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "80vh",
          padding: "20px",
        }}
      >
        <Container className="d-flex justify-content-center align-items-center">
          <Card
            className="p-3 shadow-lg rounded"
            style={{
              maxWidth: "450px",
              width: "100%",
              backgroundColor: isDarkMode ? "#1E1E2F" : "#f9f9f9",
              color: isDarkMode ? "#FFF" : "#000",
              border: "3px solid transparent !important",
              borderImage:
                "linear-gradient(90deg, rgb(255, 110, 221), rgb(107, 120, 255)) 1",
            }}
          >
            <Card.Body>
              <div className="text-center mb-4">
                <img
                  src={icons.unificationIcon}
                  alt="logo"
                  style={{ maxWidth: "80px", height: "auto" }}
                />
              </div>

              <h5
                className="text-center mb-3"
                style={{
                  color: isDarkMode ? "#DDD" : "#555",
                }}
              >
                Enter the OTP sent to your email
              </h5>
              <Formik
                initialValues={{ otp: "" }}
                validationSchema={validationSchema}
                onSubmit={handleVerifyOtp}
                enableReinitialize
              >
                {({ isSubmitting }) => (
                  <Form>
                    <div className="mb-4">
                      <label
                        htmlFor="otp"
                        style={{ color: isDarkMode ? "#FFF" : "#000" }}
                      >
                        OTP
                      </label>
                      <Field
                        id="otp"
                        name="otp"
                        type="text"
                        placeholder="Enter your 6-digit OTP"
                        className="form-control py-1"
                        style={{
                          fontSize: "1rem",
                          border: "1px solid",
                          borderColor: isDarkMode ? "#4D4D7E" : "#CCC",
                        }}
                      />
                      <ErrorMessage
                        name="otp"
                        component="div"
                        className="text-danger mt-1"
                      />
                    </div>

                    <Button
                      btnStyle="PD"
                      className="w-100 py-2"
                      btnType="submit"
                      btnText={isSubmitting ? "Verifying..." : "Verify OTP"}
                      disabled={isSubmitting || loading}
                      style={{
                        color: "#FFF",
                        border: "none",
                        fontSize: "1.2rem",
                        fontWeight: "bold",
                      }}
                    />
                  </Form>
                )}
              </Formik>

              {error && (
                <div className="text-danger text-center mt-3">{error}</div>
              )}

              <hr
                style={{
                  borderColor: isDarkMode ? "#4D4D7E" : "#DDD",
                  marginTop: "20px",
                }}
              />

              <div className="text-center mt-3">
                <span>
                  Didn’t receive the OTP?{" "}
                  {resendTimer > 0 ? (
                    <span style={{ color: "#FF6EDD" }}>
                      Resend OTP in {formatTime(resendTimer)}
                    </span>
                  ) : (
                    <button
                      className="btn btn-link"
                      style={{ color: "#FF6EDD", textDecoration: "none" }}
                      onClick={handleResendOtp}
                      disabled={loading}
                    >
                      Resend OTP
                    </button>
                  )}
                </span>
              </div>
            </Card.Body>
          </Card>
        </Container>
      </div>
    </>
  );
};

export default OTP;
