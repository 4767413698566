import { UrlShowCard } from "components";
import "./Unode.scss";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { icons } from "utils/constants";
import { getDataFromLocalStorage } from "utils/helpers";

const Unode = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const reduxData = useSelector((state) => state.global);
  const { isDarkMode } = reduxData || {};
  const [pageLoading, setPageLoading] = useState(false);
  const token = getDataFromLocalStorage();
  const unodeUrl = "https://unode-beta.unification.io/";
  // const unodeUrl = "http://localhost:3001/";

  return (
    <div
      id="unode-container"
      className={`cp-38 ${isDarkMode ? "custom-scroll-dark" : "custom-scroll"}`}
    >
      {pageLoading ? (
        <>
          <div
            className={`${
              isDarkMode ? "skeleton-dark" : "skeleton"
            } h-55 br-10`}
          />
          <div className="row cmt-26">
            {[...Array(4)].map((_, index) => (
              <div key={index} className="col-xl-3 col-lg-4 col-sm-6 col-12">
                <div
                  className={`${
                    isDarkMode ? "skeleton-dark" : "skeleton"
                  } node-container h-300 border-0`}
                />
              </div>
            ))}
          </div>
        </>
      ) : (
        <>
          <div className="col-md-8 cmb-30">
            <UrlShowCard
              title="UNoDE"
              isDarkMode={isDarkMode}
              link={unodeUrl}
              icon={icons.unodeChain}
              token={token?.token}
              noFilter
              titleLogo={icons.logo}
              linkIcon={icons.bLink}
              listdata={[
                {
                  title: "Manage Projects on Multiple Blockchains",
                  icon: isDarkMode
                    ? icons.activeProjectLight
                    : icons.activeProject,
                },
                {
                  title: "Choose Subscription Plans to Fit Your Needs",
                  icon: isDarkMode
                    ? icons.activeSubscriptionLight
                    : icons.activeSubscription,
                },
                {
                  title: "Add Nodes and Earn Based on Traffic",
                  icon: isDarkMode ? icons.coinLight : icons.coindark,
                },

                {
                  title:
                    "Enjoy Secure and Scalable Solutions for Your Applications",
                  icon: isDarkMode
                    ? icons.activeMyProjectLight
                    : icons.activeMyProject,
                },
                {
                  title:
                    "Integrate with Popular Tools for Enhanced Productivity",
                  icon: isDarkMode
                    ? icons.activedashBoardDark
                    : icons.activeDashboard,
                },
              ]}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default Unode;
