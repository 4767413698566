import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { icons } from "utils/constants";
import { Card, Container } from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

import "./SignUp.scss";
import { Button } from "components";
import { userSignup } from "store/globalSlice";

// Validation schema using Yup
const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email format")
    .required("Email is required"),
  password: Yup.string()
    .min(6, "Password must be at least 6 characters")
    .required("Password is required"),
  retryPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Please confirm your password"),
});

const Signup = () => {
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");
  const dispatch = useDispatch();
  // Accessing global Redux state
  const { isDarkMode } = useSelector((state) => state.global) || {};

  const navigate = useNavigate();

  const handleSignup = async (values) => {
    setLoading(true);
    setError("");

    try {
      const payload = {
        email: values.email,
        password: values.password,
      };
      const res = await dispatch(userSignup(payload));
      if (res?.status === 200) {
        navigate("/signup/otp", {
          state: { email: values.email, password: values.password },
        });
      } else {
        setError(res?.data?.error || "Signup failed. Please try again.");
      }
    } catch (err) {
      setError(
        err?.response?.data?.error ||
          "An unexpected error occurred. Please try again."
      );
      console.error("Signup error: ", err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {/* Signup Form Section */}
      <div
        className={`signup-page ${isDarkMode ? "dark-mode" : "light-mode"}`}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "80vh",
          padding: "20px",
        }}
      >
        <Container className="d-flex justify-content-center align-items-center">
          <Card
            className="p-3 shadow-lg rounded"
            style={{
              maxWidth: "450px",
              width: "100%",
              backgroundColor: isDarkMode ? "#1E1E2F" : "#f9f9f9",
              color: isDarkMode ? "#FFF" : "#000",
              border: "3px solid transparent !important",
              borderImage:
                "linear-gradient(90deg, rgb(255, 110, 221), rgb(107, 120, 255)) 1",
            }}
          >
            <Card.Body>
              <div className="text-center mb-4">
                <img
                  src={icons.unificationIcon}
                  alt="logo"
                  style={{ maxWidth: "80px", height: "auto" }}
                />
              </div>

              <div className="mt-3">
                <h5
                  className="text-center mb-3"
                  style={{
                    color: isDarkMode ? "#DDD" : "#555",
                  }}
                >
                  Create Your Account
                </h5>
                {error && (
                  <div className="mb-4 p-2 bg-red-100 border border-red-400 text-red-700 rounded">
                    {error}
                  </div>
                )}
                <Formik
                  initialValues={{ email: "", password: "", retryPassword: "" }}
                  validationSchema={validationSchema}
                  onSubmit={handleSignup}
                >
                  {({ isSubmitting }) => (
                    <Form>
                      <div className="mb-3">
                        <label
                          htmlFor="email"
                          style={{ color: isDarkMode ? "#FFF" : "#000" }}
                        >
                          Email
                        </label>
                        <Field
                          id="email"
                          name="email"
                          type="email"
                          placeholder="Enter your email"
                          className="form-control py-1"
                          style={{
                            fontSize: "1rem",
                            // color: isDarkMode ? "#FFF" : "#000",
                            border: "1px solid",
                            borderColor: isDarkMode ? "#4D4D7E" : "#CCC",
                          }}
                        />
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="text-danger mt-1"
                        />
                      </div>

                      <div className="mb-3">
                        <label
                          htmlFor="password"
                          style={{ color: isDarkMode ? "#FFF" : "#000" }}
                        >
                          Password
                        </label>
                        <Field
                          id="password"
                          name="password"
                          type="password"
                          placeholder="Enter your password"
                          className="form-control py-1"
                          style={{
                            fontSize: "1rem",
                            // color: isDarkMode ? "#FFF" : "#000",
                            border: "1px solid",
                            borderColor: isDarkMode ? "#4D4D7E" : "#CCC",
                          }}
                        />
                        <ErrorMessage
                          name="password"
                          component="div"
                          className="text-danger mt-1"
                        />
                      </div>

                      <div className="mb-4">
                        <label
                          htmlFor="retryPassword"
                          style={{ color: isDarkMode ? "#FFF" : "#000" }}
                        >
                          Confirm Password
                        </label>
                        <Field
                          id="retryPassword"
                          name="retryPassword"
                          type="password"
                          placeholder="Re-enter your password"
                          className="form-control py-1"
                          style={{
                            fontSize: "1rem",
                            // color: isDarkMode ? "#FFF" : "#000",
                            border: "1px solid",
                            borderColor: isDarkMode ? "#4D4D7E" : "#CCC",
                          }}
                        />
                        <ErrorMessage
                          name="retryPassword"
                          component="div"
                          className="text-danger mt-1"
                        />
                      </div>

                      <Button
                        btnStyle="PD"
                        className="w-100 py-2"
                        btnType="submit"
                        btnText={isSubmitting ? "Signing Up..." : "Sign Up"}
                        disabled={isSubmitting}
                        style={{
                          color: "#FFF",
                          border: "none",
                          fontSize: "1.2rem",
                          fontWeight: "bold",
                        }}
                      />
                    </Form>
                  )}
                </Formik>
              </div>

              <div className="text-center mt-3">
                <span>
                  Already have an account?{" "}
                  <Link
                    to="/login"
                    style={{
                      color: "#FF6EDD",
                      textDecoration: "none",
                      fontWeight: "bold",
                    }}
                  >
                    Log In
                  </Link>
                </span>
              </div>
            </Card.Body>
          </Card>
        </Container>
      </div>
    </>
  );
};

export default Signup;
